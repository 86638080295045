import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, ButtonGroup } from "react-bootstrap";

import { monthsSelectOptions, isLivingSelectOptions, funeralTypesOptions } from '../../libs/constants';

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";
import UIFieldNumber from '../../ui/UIFieldNumber';
import UIFormFieldset from '../../ui/UIFormFieldset';
import UISelect from '../../ui/UISelect';

import Icon from '../../ui/Icon';

import BasicTooltip from '../../ui/Tooltip';

import { getCountriesOptions } from '../../libs/utils';
import UIFieldAutocomplete from '../../ui/UIFieldAutocomplete';

import { fetchCity } from '../../services/genealogy';
import UIFieldText from '../../ui/UIFieldText';
import ButtonGroupGrade from '../../components/ButtonGroupGrade';
import UIButton from '../../ui/UIButton';

function TabLife() {

    const { peopleData, updatePeopleData } = useGenealogyStore();

    const countriesOptions = getCountriesOptions();

    const [citiesBirthOptions, setCitiesBirthOptions] = useState([]);
    const [citiesDeathOptions, setCitiesDeathOptions] = useState([]);
    const [citiesFuneralOptions, setCitiesFuneralOptions] = useState([]);

    useEffect(() => {
        if (peopleData.birth_city !== "") {
            fetchCity(peopleData.birth_city).then((response) => {
                setCitiesBirthOptions(response);
            });
        }
        if (peopleData.death_city !== "") {
            fetchCity(peopleData.death_city).then((response) => {
                setCitiesDeathOptions(response);
            });
        }
        if (peopleData.funeral_city !== "") {
            fetchCity(peopleData.funeral_city).then((response) => {
                setCitiesFuneralOptions(response);
            });
        }
    }, [peopleData]);

    return (<>
        <UIFormFieldset legend="Naissance" icon="baby">
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Date de naissance</Form.Label>
                        <ButtonGroupGrade field="birth_date_grade" />
                    </div>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <UIFieldNumber
                        type="number"
                        // label="Date de naissance"
                        placeholder="Entrez le jour de naissance"
                        value={peopleData.birth_date.split("-").pop()}
                        setValue={(v) => updatePeopleData('birth_date', peopleData.birth_date.split("-").shift() + '-' + v)}
                        min={1} max={31}
                    />
                </Col>
                <Col>
                    <UISelect
                        placeholder="Sélectionnez le mois de naissance"
                        options={monthsSelectOptions}
                        value={monthsSelectOptions.filter((option) => option.value == peopleData.birth_date.split("-").shift())}
                        setValue={(v) => updatePeopleData('birth_date', v + '-' + peopleData.birth_date.split("-").pop())}
                    />
                </Col>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez l'année de naissance"
                        value={peopleData.birth_year}
                        setValue={(v) => updatePeopleData('birth_year', v)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Heure de naissance</Form.Label>
                        <ButtonGroupGrade field="birth_time_grade" />
                    </div>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez l'heure de naissance"
                        value={peopleData.birth_time.split(":").shift()}
                        setValue={(v) => updatePeopleData('birth_time', v + ':' + peopleData.birth_time.split(":").pop())}
                        min={0} max={23} zeroFill
                    />
                </Col>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez les minutes de naissance"
                        value={peopleData.birth_time.split(":").pop()}
                        setValue={(v) => updatePeopleData('birth_time', peopleData.birth_time.split(":").shift() + ':' + v)}
                        min={0} max={59} zeroFill
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Lieu de naissance</Form.Label>
                        <ButtonGroupGrade field="birth_place_grade" />
                    </div>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <UISelect
                        placeholder="Sélectionnez le pays de naissance" type="countries"
                        options={countriesOptions}
                        value={countriesOptions.filter((option) => option.value == peopleData.birth_country)}
                        setValue={(v) => updatePeopleData('birth_country', v)}
                    />
                </Col>
                <Col>
                    <UIFieldAutocomplete
                        placeholder="Entrez la ville de naissance"
                        options={citiesBirthOptions}
                        inactive={peopleData.birth_country !== 'fr'}
                        onSearch={(query) => {
                            fetchCity(query).then((response) => setCitiesBirthOptions(response))
                        }}
                        value={peopleData.birth_city}
                        setValue={(v, o) => {
                            updatePeopleData('birth_city', v);
                            if (o.zipcode !== undefined) {
                                updatePeopleData('birth_zipcode', o.zipcode);
                            }
                        }}
                    />
                </Col>
                <Col>
                    <UIFieldText
                        placeholder="Entrez le code postal de la ville de naissance"
                        value={peopleData.birth_zipcode}
                        setValue={(v) => updatePeopleData('birth_zipcode', v)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Acte de naissance</Form.Label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {peopleData.birth_certificate !== "" && <div className='d-flex px-2'>
                        <UIButton
                            variant="outline-secondary"
                            icon="file-certificate"
                            label="Voir le document"
                            className="flex-fill me-3"
                            onClick={() => window.open(`https://${peopleData.birth_certificate}`)}
                        />
                        <UIButton
                            variant="outline-danger"
                            icon="trash"
                            label="Supprimer le document"
                            className="flex-fill ms-3"
                            onClick={() => {
                                let newDocuments = peopleData.documents;
                                newDocuments = newDocuments.map((a_document) => {
                                    if (a_document.url === peopleData.birth_certificate) {
                                        a_document.to_delete = true;
                                    }
                                    return a_document;
                                });
                                updatePeopleData('documents', newDocuments);
                                updatePeopleData('birth_certificate', '');
                            }}
                        />
                    </div>}
                    {peopleData.birth_certificate === "" && <div className='d-flex px-2'>
                        <UIButton
                            variant="outline-primary"
                            icon="file-upload"
                            label="Envoyer un document"
                            className="flex-fill"
                            onClick={() => window.open(`https://${peopleData.birth_certificate}`)}
                        />
                    </div>}
                </Col>
            </Row>
        </UIFormFieldset>
        <UIFormFieldset legend="Statut" icon="heart-pulse">
            <Row>
                <Col>
                    <UISelect
                        placeholder="Sélectionnez le statut de la personne"
                        options={isLivingSelectOptions}
                        value={isLivingSelectOptions.filter((option) => option.value == peopleData.is_living)}
                        setValue={(v) => updatePeopleData('is_living', v)}
                    />
                </Col>
            </Row>
        </UIFormFieldset>
        {peopleData.is_living === '0' && <UIFormFieldset legend="Décès" icon="coffin-cross">
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Date de décès</Form.Label>
                        <ButtonGroupGrade field="death_date_grade" />
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <UIFieldNumber
                        type="number"
                        // label="Date de décès"
                        placeholder="Entrez le jour de décès"
                        value={peopleData.death_date.split("-").pop()}
                        setValue={(v) => updatePeopleData('death_date', peopleData.death_date.split("-").shift() + '-' + v)}
                        min={1} max={31}
                    />
                </Col>
                <Col>
                    <UISelect
                        placeholder="Sélectionnez le mois de décès"
                        options={monthsSelectOptions}
                        value={monthsSelectOptions.filter((option) => option.value == peopleData.death_date.split("-").shift())}
                        setValue={(v) => updatePeopleData('death_date', v + '-' + peopleData.death_date.split("-").pop())}
                    />
                </Col>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez l'année de décès"
                        value={peopleData.death_year}
                        setValue={(v) => updatePeopleData('death_year', v)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Heure de décès</Form.Label>
                        <ButtonGroupGrade field="death_time_grade" />
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez l'heure de décès"
                        value={peopleData.death_time.split(":").shift()}
                        setValue={(v) => updatePeopleData('death_time', v + ':' + peopleData.death_time.split(":").pop())}
                        min={0} max={23} zeroFill
                    />
                </Col>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez les minutes de décès"
                        value={peopleData.death_time.split(":").pop()}
                        setValue={(v) => updatePeopleData('death_time', peopleData.death_time.split(":").shift() + ':' + v)}
                        min={0} max={59} zeroFill
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Lieu de décès</Form.Label>
                        <ButtonGroupGrade field="death_place_grade" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <UISelect
                        placeholder="Sélectionnez le pays de décès" type="countries"
                        options={countriesOptions}
                        value={countriesOptions.filter((option) => option.value == peopleData.death_country)}
                        setValue={(v) => updatePeopleData('death_country', v)}
                    />
                </Col>
                <Col>
                    <UIFieldAutocomplete
                        placeholder="Entrez la ville de décès"

                        options={citiesDeathOptions}
                        onSearch={(query) => {
                            fetchCity(query).then((response) => setCitiesDeathOptions(response))
                        }}
                        value={peopleData.death_city}
                        setValue={(v, o) => {
                            updatePeopleData('death_city', v);
                            if (o.zipcode !== undefined) {
                                updatePeopleData('death_zipcode', o.zipcode);
                            }
                        }}
                    />
                </Col>
                <Col>
                    <UIFieldText
                        placeholder="Entrez le code postal de la ville de décès"
                        value={peopleData.death_zipcode}
                        setValue={(v) => updatePeopleData('death_zipcode', v)}
                    />
                </Col>
            </Row>
        </UIFormFieldset>}
        {peopleData.is_living === '0' && <UIFormFieldset legend="Funérailles" icon="tombstone">
            <Row className='mb-4'>
                <Col>
                    {/* <div className='d-flex px-2'> */}
                    {/* <Form.Label className='text-muted'></Form.Label> */}
                    {/* <ButtonGroupGrade field="death_date_grade" /> */}
                    <UISelect
                        placeholder="Choisissez le type de funérailles"
                        label='Type de funérailles'
                        options={funeralTypesOptions}
                        value={funeralTypesOptions.filter((option) => option.value == peopleData.funeral_type)}
                        setValue={(v) => updatePeopleData('funeral_type', v)}
                    />
                    {/* </div> */}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Date des funérailles</Form.Label>
                        <ButtonGroupGrade field="funeral_date_grade" />
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <UIFieldNumber
                        type="number"
                        // label="Date des funérailles"
                        placeholder="Entrez le jour des funérailles"
                        value={peopleData.funeral_date.split("-").pop()}
                        setValue={(v) => updatePeopleData('funeral_date', peopleData.funeral_date.split("-").shift() + '-' + v)}
                        min={1} max={31}
                    />
                </Col>
                <Col>
                    <UISelect
                        placeholder="Sélectionnez le mois des funérailles"
                        options={monthsSelectOptions}
                        value={monthsSelectOptions.filter((option) => option.value == peopleData.funeral_date.split("-").shift())}
                        setValue={(v) => updatePeopleData('funeral_date', v + '-' + peopleData.funeral_date.split("-").pop())}
                    />
                </Col>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez l'année des funérailles"
                        value={peopleData.funeral_year}
                        setValue={(v) => updatePeopleData('funeral_year', v)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Heure des funérailles</Form.Label>
                        <ButtonGroupGrade field="funeral_time_grade" />
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez l'heure des funérailles"
                        value={peopleData.funeral_time.split(":").shift()}
                        setValue={(v) => updatePeopleData('funeral_time', v + ':' + peopleData.funeral_time.split(":").pop())}
                        min={0} max={23} zeroFill
                    />
                </Col>
                <Col>
                    <UIFieldNumber
                        type="number"
                        placeholder="Entrez les minutes des funérailles"
                        value={peopleData.funeral_time.split(":").pop()}
                        setValue={(v) => updatePeopleData('funeral_time', peopleData.funeral_time.split(":").shift() + ':' + v)}
                        min={0} max={59} zeroFill
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Lieu des funérailles</Form.Label>
                        <ButtonGroupGrade field="funeral_place_grade" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <UISelect
                        placeholder="Sélectionnez le pays des funérailles" type="countries"
                        options={countriesOptions}
                        value={countriesOptions.filter((option) => option.value == peopleData.funeral_country)}
                        setValue={(v) => updatePeopleData('funeral_country', v)}
                    />
                </Col>
                <Col>
                    <UIFieldAutocomplete
                        placeholder="Entrez la ville des funérailles"

                        options={citiesFuneralOptions}
                        onSearch={(query) => {
                            fetchCity(query).then((response) => setCitiesFuneralOptions(response))
                        }}
                        value={peopleData.funeral_city}
                        setValue={(v, o) => {
                            updatePeopleData('funeral_city', v);
                            if (o.zipcode !== undefined) {
                                updatePeopleData('funeral_zipcode', o.zipcode);
                            }
                        }}
                    />
                </Col>
                <Col>
                    <UIFieldText
                        placeholder="Entrez le code postal de la ville des funérailles"
                        value={peopleData.funeral_zipcode}
                        setValue={(v) => updatePeopleData('funeral_zipcode', v)}
                    />
                </Col>
            </Row>
        </UIFormFieldset>}
    </>)

}

export default TabLife;