import React, { useState, useContext, useEffect } from 'react';

import { Offcanvas, Form, Button, ButtonGroup } from 'react-bootstrap';

import Icon from '../ui/Icon';

import useAppStore from '../stores/AppStore';
import useGenealogyStore from '../stores/GenealogyStore';
import UISelect from '../ui/UISelect';

function MenuTree() {

    const {
        showedMenu, closeMenu, appTheme
    } = useAppStore();

    const { peoplesOptions, treeViewedPeople, treeViewedDirection, fetchTree,
        treeSeparationSiblings, treeSeparationNonSiblings, setTreeSeparationSiblings, setTreeSeparationNonSiblings,
        treeZoom, setTreeZoom, treeDepthFactor, setTreeDepthFactor
    } = useGenealogyStore();

    return (
        <Offcanvas show={showedMenu === "tree"} onHide={closeMenu} className={appTheme === "dark" ? 'bg-black text-white' : 'bg-white text-black'} placement="end" backdrop={false}>
            <Offcanvas.Header closeButton closeVariant={appTheme === "dark" ? "white" : ""}>
                <Offcanvas.Title className='text-theme'>Réglage de l'arbre</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <UISelect
                    label="Personne de l'arbre"
                    placeholder="Choisissez une personne"
                    options={peoplesOptions}
                    type="peoples_multisex"
                    value={peoplesOptions.filter((people) => people.value === treeViewedPeople)}
                    setValue={(v) => fetchTree(v, treeViewedDirection)}
                    searchable={true}
                />
                <Form.Group className='px-2 mt-3'>
                    <Form.Label className='text-muted'>Sens de l'arbre</Form.Label>
                    <div className='d-grid'>
                        <ButtonGroup>
                            <Button variant='outline-primary'
                                active={treeViewedDirection === "ancestors"}
                                onClick={() => fetchTree(treeViewedPeople, 'ancestors')}
                            ><Icon name="up-from-line" fixedWidth className="me-2" />Ascendants</Button>
                            <Button variant='outline-primary'
                                active={treeViewedDirection === "descendants"}
                                onClick={() => fetchTree(treeViewedPeople, 'descendants')}
                            ><Icon name="down-from-line" fixedWidth className="me-2" />Descendants</Button>
                        </ButtonGroup>
                    </div>

                </Form.Group>
                <Form.Group className='px-2 mt-3'>
                    <Form.Label className='text-muted'>Séparation Siblings</Form.Label>
                    <Form.Range
                        min={0}
                        max={2}
                        step={0.1}
                        value={treeSeparationSiblings}
                        onChange={(e) => setTreeSeparationSiblings(parseFloat(e.target.value))}
                    />
                </Form.Group>
                <Form.Group className='px-2 mt-3'>
                    <Form.Label className='text-muted'>Séparation Non Siblings</Form.Label>
                    <Form.Range
                        min={0}
                        max={2}
                        step={0.1}
                        value={treeSeparationNonSiblings}
                        onChange={(e) => setTreeSeparationNonSiblings(parseFloat(e.target.value))}
                    />
                </Form.Group>
                <Form.Group className='px-2 mt-3'>
                    <Form.Label className='text-muted'>Zoom</Form.Label>
                    <Form.Range
                        min={0}
                        max={2}
                        step={0.1}
                        value={treeZoom}
                        onChange={(e) => setTreeZoom(parseFloat(e.target.value))}
                    />
                </Form.Group>
                <Form.Group className='px-2 mt-3'>
                    <Form.Label className='text-muted'>Depth Factor</Form.Label>
                    <Form.Range
                        min={0}
                        max={1000}
                        step={10}
                        value={treeDepthFactor}
                        onChange={(e) => setTreeDepthFactor(parseInt(e.target.value))}
                    />
                </Form.Group>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuTree;