import { Row, Col, Form } from 'react-bootstrap';
import UISelect from '../../ui/UISelect';

import useGenealogyStore from '../../stores/GenealogyStore';
import UIButton from '../../ui/UIButton';
import { useEffect, useState } from 'react';

export default function FormGroupChild({ childIndex, childID }) {

    const { peopleData, updatePeopleData, peopleViewed, peoplesOptions } = useGenealogyStore();

    const [selectedPeople, setSelectedPeople] = useState([]);

    useEffect(() => {
        setSelectedPeople(peoplesOptions.filter((option) => option.value === childID));
    }, [])

    return (<div className="form-group-child">
        <Row>
            <Col>
                <Form.Label className="px-2 text-muted">Enfant n°{childIndex}</Form.Label>
            </Col>
            <Col className='text-end'>
                <UIButton
                    variant="outline-danger"
                    size='xs'
                    className='me-2'
                    icon="trash"
                    label="Supprimer l'enfant"
                    onClick={() => {
                        let newArray = peopleData.childrens_ids.filter((a_childID) => a_childID !== childID);
                        updatePeopleData("childrens_ids", newArray);
                    }}
                />

            </Col>
        </Row>
        <Row>
            <Col>
                <UISelect
                    placeholder="Sélectionnez l'enfant de la personne"
                    options={peoplesOptions.filter((option) => option.value !== peopleViewed)}
                    value={selectedPeople}
                    setValue={(v) => {
                        let newArray = peopleData.childrens_ids;
                        let index = peopleData.childrens_ids.indexOf(childID + "");
                        // newArray[newArray.indexOf(childID)] = v;
                        console.log(newArray);
                        console.log(childID);
                        console.log(index);
                        console.log(v);
                        newArray[index] = v;
                        console.log(newArray);
                        setSelectedPeople(peoplesOptions.filter((option) => option.value === v));
                        updatePeopleData("childrens_ids", newArray);
                    }}
                    type="peoples_multisex"
                />
            </Col>
        </Row>
    </div>)
}