import React, { useState, useEffect } from "react";
import { Form, ButtonGroup, Button } from "react-bootstrap";

import { Editor, EditorState, ContentState, RichUtils, Modifier } from 'draft-js';
import { convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { convertToHTML, convertFromHTML } from 'draft-convert';

import Icon from "../Icon";

// import './style.scss';

function UIEditor({ label, placeholder, value, setValue }) {

    const [isInit, setIsInit] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        if (value) {
            setEditorState(EditorState.createWithContent(
                convertFromHTML(value)
            ))
        }
        setIsInit(true);
    }, []);

    useEffect(() => {
        if (editorState && isInit) {
            const content = editorState.getCurrentContent();
            setValue(convertToHTML(content))
        }
    }, [editorState]);

    const updateEditorState = editorState => setEditorState(editorState);

    const applyStyle = style => {
        const editorStateFocused = EditorState.forceSelection(
            editorState,
            editorState.getSelection(),
        );
        updateEditorState(RichUtils.toggleInlineStyle(editorStateFocused, style));
    };

    const isStyleActive = style => {
        const draftStyle = editorState.getCurrentInlineStyle();
        return draftStyle.has(style);
    };

    const applyBloc = style => {
        const editorStateFocused = EditorState.forceSelection(
            editorState,
            editorState.getSelection(),
        );
        updateEditorState(RichUtils.toggleBlockType(editorStateFocused, style));
    };

    const isBlocActive = style => {
        const currentSelection = editorState.getSelection();
        const currentKey = currentSelection.getStartKey();
        const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
        return currentBlock.getType() === style;
    };

    return (<>
        <Form.Group className="px-2">
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <div className="px-2 mt-2">
                <ButtonGroup size="sm" className='mb-3'>
                    <Button
                        variant={isStyleActive("BOLD") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyStyle("BOLD")}>
                        <Icon fixedWidth name="bold" />
                    </Button>
                    <Button
                        variant={isStyleActive("ITALIC") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyStyle("ITALIC")}>
                        <Icon fixedWidth name="italic" />
                    </Button>
                    <Button
                        variant={isStyleActive("UNDERLINE") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyStyle("UNDERLINE")}>
                        <Icon fixedWidth name="underline" />
                    </Button>
                    <Button
                        variant={isStyleActive("STRIKETHROUGH") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyStyle("STRIKETHROUGH")}>
                        <Icon fixedWidth name="strikethrough" />
                    </Button>
                    <Button
                        variant={isStyleActive("CODE") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyStyle("CODE")}>
                        <Icon fixedWidth name="code-simple" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup size="sm" className='mb-3 ms-3'>
                    <Button
                        variant={isBlocActive("unstyled") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("unstyled")}>
                        <Icon fixedWidth name="paragraph" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup size="sm" className='mb-3 ms-3'>
                    <Button
                        variant={isBlocActive("header-one") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("header-one")}>
                        <Icon fixedWidth name="h1" />
                    </Button>
                    <Button
                        variant={isBlocActive("header-two") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("header-two")}>
                        <Icon fixedWidth name="h2" />
                    </Button>
                    <Button
                        variant={isBlocActive("header-three") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("header-three")}>
                        <Icon fixedWidth name="h3" />
                    </Button>
                    <Button
                        variant={isBlocActive("header-four") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("header-four")}>
                        <Icon fixedWidth name="h4" />
                    </Button>
                    <Button
                        variant={isBlocActive("header-five") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("header-five")}>
                        <Icon fixedWidth name="h5" />
                    </Button>
                    <Button
                        variant={isBlocActive("header-six") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("header-six")}>
                        <Icon fixedWidth name="h6" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup size="sm" className='mb-3 ms-3'>
                    <Button
                        variant={isBlocActive("unordered-list-item") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("unordered-list-item")}>
                        <Icon fixedWidth name="list" />
                    </Button>
                    <Button
                        variant={isBlocActive("ordered-list-item") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("ordered-list-item")}>
                        <Icon fixedWidth name="list-ol" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup size="sm" className='mb-3 ms-3'>
                    <Button
                        variant={isBlocActive("blockquote") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("blockquote")}>
                        <Icon fixedWidth name="quote-left" />
                    </Button>
                    <Button
                        variant={isBlocActive("code-block") ? 'secondary' : 'outline-secondary'}
                        onClick={() => applyBloc("code-block")}>
                        <Icon fixedWidth name="code" />
                    </Button>
                </ButtonGroup>
            </div>
            <Editor
                editorState={editorState}
                onChange={setEditorState}
                placeholder={placeholder}
            />
        </Form.Group>
    </>)
}

export default UIEditor;