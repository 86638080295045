const API_URL = 'api.guru-worlds.com';
const PASSPORT_NAME = 'passport_genealogy';

export const readPeoples = async () => {
    const API_CALL_URL = API_URL + '/genealogy/peoples';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'GET',
        headers: headers
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    console.info("Peoples data", data.result);
    return data.result;
};

export const createPeople = async (peopleData) => {
    const API_CALL_URL = API_URL + '/genealogy/people';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(peopleData)
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updatePeople = async (peopleID, peopleData) => {
    const API_CALL_URL = API_URL + '/genealogy/people/' + peopleID;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(peopleData)
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const readPictures = async () => {
    const API_CALL_URL = API_URL + '/genealogy/pictures';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'GET',
        headers: headers
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    console.info("Pictures data", data.result);
    return data.result;
};

export const createPicture = async (pictureData) => {
    const API_CALL_URL = API_URL + '/genealogy/picture';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(pictureData)
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const updatePicture = async (pictureID, pictureData) => {
    const API_CALL_URL = API_URL + '/genealogy/picture/' + pictureID;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(pictureData)
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const deletePicture = async (pictureID) => {
    const API_CALL_URL = API_URL + '/genealogy/picture/' + pictureID;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'DELETE',
        headers: headers
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    return data;
};

export const fetchAncestors = async (peopleID, maxDepth) => {
    const API_CALL_URL = API_URL + '/genealogy/people/' + peopleID + '/ancestors';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'GET',
        headers: headers,
        // params: JSON.stringify({ depth: maxDepth })
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    console.info("Ancestors data", data.result);
    return data.result;
};

export const fetchDescendants = async (peopleID, maxDepth) => {
    const API_CALL_URL = API_URL + '/genealogy/people/' + peopleID + '/descendants';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'GET',
        headers: headers,
        // body: JSON.stringify({ depth: maxDepth })
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    console.info("Descendants data", data.result);
    return data.result;
};

export const fetchCity = async (cityQuery) => {
    const API_CALL_URL = API_URL + '/data/city/' + cityQuery;
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'GET',
        headers: headers
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    // console.info("City data",data.result);
    return data.result;
};


export const readStats = async () => {
    const API_CALL_URL = API_URL + '/genealogy/stats';
    const passport = localStorage.getItem(PASSPORT_NAME);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + passport);
    headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
    const options = {
        method: 'GET',
        headers: headers
    };
    const response = await fetch('https://' + API_CALL_URL, options);
    const data = await response.json();
    console.info("Stats data", data.result);
    return data.result;
};