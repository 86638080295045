import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import useAppStore from '../stores/AppStore';

import Icon from '../ui/Icon.jsx';
import { useTranslation } from 'react-i18next';

function AppHeader({ isHome, page }) {

    const { t, i18n } = useTranslation();

    const { appName, userIsAdmin, showMenu } = useAppStore();

    if (isHome) {
        return (
            <>
                <header className="App-header">
                    <h1>{appName}</h1>
                </header>
                {userIsAdmin && <nav className='hamburger hamburger-left'>
                    <span onClick={() => showMenu("app")}><Icon name="chevron-right" /></span>
                </nav>}
                {page === "tree" && <nav className='hamburger hamburger-right'>
                    <span onClick={() => showMenu("tree")}><Icon name="cogs" /></span>
                </nav>}
            </>
        )
    }

    return (
        <>
            <header className="App-header">
                <h1><Link to="/">{appName}</Link></h1>
            </header>
            {userIsAdmin && <nav className='hamburger hamburger-left'>
                <span onClick={() => showMenu('app')}><Icon name="chevron-right" /></span>
            </nav>}
            {page === "tree" && <nav className='hamburger hamburger-right'>
                <span onClick={() => showMenu("tree")}><Icon name="cogs" /></span>
            </nav>}
        </>
    )

}

export default AppHeader;