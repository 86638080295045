import React, { useContext, useState } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import useAppStore from '../stores/AppStore.jsx';

import { Nav } from 'react-bootstrap';

function AppNav() {

    const { t, i18n } = useTranslation();

    const { appName } = useAppStore();

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedView, setSelectedView] = useState(location.pathname);

    return (
        <>
            <Nav
                className='justify-content-center bg-dark'
                activeKey={selectedView}
                onSelect={(selectedKey) => { setSelectedView(selectedKey); navigate(selectedKey); }}
            >
                {/* <Nav.Item>
                    <Nav.Link eventKey="/">Accueil</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                    <Nav.Link eventKey="/">Liste des personnes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/tree">Arbre généalogique</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/photos">Galerie photo</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                    <Nav.Link eventKey="/stats">Statistiques</Nav.Link>
                </Nav.Item> */}
            </Nav>
        </>
    )

}

export default AppNav;