import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, ButtonGroup } from "react-bootstrap";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";

// UI
import UIFieldNumber from '../../ui/UIFieldNumber';
import UIFormFieldset from '../../ui/UIFormFieldset';
import UISelect from '../../ui/UISelect';
import UIFieldAutocomplete from '../../ui/UIFieldAutocomplete';
import UIFieldText from '../../ui/UIFieldText';

import Icon from '../../ui/Icon';
import BasicTooltip from '../../ui/Tooltip';
import UIMasonry from '../../ui/UIMasonry';
import { copyFile } from '../../services/cloud';

function TabPhotos() {

    const { peopleData, updatePeopleData } = useGenealogyStore();

    const handleSelectMainPicture = (fileURL) => {
        // e.preventDefault();
        const now = new Date();
        let fileName = "picture_main_" + peopleData.name_last + "_" + peopleData.name_first + "_" + now.toLocaleDateString("en-US").replaceAll("/", "-") + "_" + now.toLocaleTimeString("fr-FR").replaceAll(":", "-");
        fileName = fileName.replaceAll(" ", "-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const filePath = "genealogy/pictures/";
        const fileData = new FormData();
        const fileActions = ['makeSquareThumbnail']
        fileData.append("file_name", fileName);
        fileData.append("file_path", filePath);
        fileData.append("file_actions", fileActions);
        copyFile(fileURL, fileData).then((response) => {
            const newFileURL = response.result.url;
            updatePeopleData('main_picture', newFileURL);
            updatePeopleData('main_picture_original', fileURL);
        });
    }

    console.log(peopleData.pictures.length);

    return (<>
        <UIFormFieldset legend="Photos" icon="camera">
            <Row>
                <Col>
                    {peopleData.pictures.length > 0 && <UIMasonry cols={6}>
                        {peopleData.pictures.map((picture) => (
                            <div key={picture.id} class="item-picture">
                                <img src={`https://${picture.url}`} />
                                <div class="picture-action cursor-pointer" onClick={() => handleSelectMainPicture(picture.url)}>
                                    <Icon name="star" iconClass={picture.url === peopleData.main_picture_original ? 'solid' : 'light'} />
                                </div>
                            </div>

                        ))}
                    </UIMasonry>}
                    {peopleData.pictures.length <= 0 && <div className='text-center text-muted lead'>
                        <Icon name="ban" className="me-2" />Aucune photo
                    </div>}
                </Col>
            </Row>
        </UIFormFieldset>
    </>)

}

export default TabPhotos;