import React from "react";
import { Button, ButtonGroup } from 'react-bootstrap';
import useGenealogyStore from "../stores/GenealogyStore";
import Icon from "../ui/Icon";
import BasicTooltip from "../ui/Tooltip";

function ButtonGroupGrade({ field }) {

    const { peopleData, updatePeopleData } = useGenealogyStore();

    return (<>
        <div className="ms-auto">
            <span className="me-3 text-muted">Valeur de la donnée</span>
            <ButtonGroup size="sm" className='btn-group-xs'>
                <Button
                    variant='outline-secondary'
                    className='text-success'
                    onClick={() => updatePeopleData(field, peopleData[field] === 'verified' ? '' : 'verified')}
                    active={peopleData[field] === 'verified'}
                >
                    <BasicTooltip content="Vérifié" placement="bottom">
                        <Icon name="check-double" fixedWidth />
                    </BasicTooltip>
                </Button>
                <Button
                    variant='outline-secondary'
                    className='text-theme'
                    onClick={() => updatePeopleData(field, peopleData[field] === 'correct' ? '' : 'correct')}
                    active={peopleData[field] === 'correct'}
                >
                    <BasicTooltip content="Connu" placement="bottom">
                        <Icon name="check" fixedWidth />
                    </BasicTooltip>
                </Button>
                <Button
                    variant='outline-secondary'
                    className='text-orange'
                    onClick={() => updatePeopleData(field, peopleData[field] === 'uncertain' ? '' : 'uncertain')}
                    active={peopleData[field] === 'uncertain'}
                >
                    <BasicTooltip content="Incertain" placement="bottom">
                        <Icon name="question" fixedWidth />
                    </BasicTooltip>
                </Button>
                <Button
                    variant='outline-secondary'
                    className='text-red'
                    onClick={() => updatePeopleData(field, peopleData[field] === 'approx' ? '' : 'approx')}
                    active={peopleData[field] === 'approx'}
                >
                    <BasicTooltip content="Approximatif" placement="bottom">
                        <Icon name="triangle-exclamation" fixedWidth />
                    </BasicTooltip>
                </Button>
            </ButtonGroup>
        </div>
    </>)

}

export default ButtonGroupGrade;