export const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    boxShadow: 'none'
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadiusTop: 0,
    width: '100%',
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    marginTop: '0',
    backgroundColor: '#222',
    borderRadius: '0 0 5px 5px',
    border: '1px solid var(--theme-color)',
    borderTop: "none",
    boxShadow: 'none',
    zIndex: 3
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#5a5a5a',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#b1b1b1',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#515151',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: '#515151',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: '#515151',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#222',
    color: '#b1b1b1',
    border: state.menuIsOpen ? '1px solid var(--theme-color)' : '1px solid #515151',
    borderBottom: state.menuIsOpen ? 'none' : '1px solid #515151',
    // minWidth: 370,
    width: '100%',
    borderRadius: state.menuIsOpen ? '5px 5px 0 0' : '5px',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid var(--theme-color)',
      borderBottom: state.menuIsOpen ? 'none' : '1px solid var(--theme-color)',
      boxShadow: 'none',
    },
    '&:focus': {
      border: '1px solid var(--theme-color)',
      borderBottom: state.menuIsOpen ? 'none' : '1px solid var(--theme-color)',
      boxShadow: 'none'
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--theme-color)' : 'transparent',
    color: state.isSelected ? 'white' : 'white',
    '&:first-child': {
      borderRadius: '0 0 0 0'
    },
    '&:last-child': {
      borderRadius: '0 0 4px 4px'
    },
    '&:hover': {
      backgroundColor: state.isSelected ? 'var(--theme-color)' : 'var(--bs-gray-800)',
      color: state.isSelected ? 'white' : "white"
    },
  }),
};

export const selectInputGroupStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 'calc(100% - 90px)',
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadiusTop: 0,
    width: '100%',
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    marginTop: '1px',
    backgroundColor: '#222',
    // borderRadius: '0 0 5px 5px',
    // borderTop: "none"
    zIndex: 3
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#5a5a5a',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#b1b1b1',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#515151',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: '#515151',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: '#515151',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#222',
    color: '#b1b1b1',
    border: state.isFocused ? '1px solid var(--theme-color)' : '1px solid #515151',
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25)' : 'none',
    minWidth: 370,
    width: '100%',
    borderRadius: '0',
    '&:hover': {
      border: '1px solid var(--theme-color)',
      boxShadow: '0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25)',
    },
    '&:focus': {
      border: '1px solid var(--theme-color)',
      boxShadow: '0 0 0 0.25rem rgba(var(--theme-color-rgb), 0.25)',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--theme-color)' : 'transparent',
    color: state.isSelected ? 'white' : 'white',
    '&:first-child': {
      borderRadius: '4px 4px 0 0'
    },
    '&:last-child': {
      borderRadius: '0 0 4px 4px'
    },
    '&:hover': {
      backgroundColor: state.isSelected ? 'var(--theme-color)' : 'var(--bs-gray-800)',
      color: state.isSelected ? 'white' : "white"
    },
  }),
};

export default selectStyles;