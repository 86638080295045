import React from "react"
import { ComposableMap, Geographies, Geography } from "react-simple-maps"

import franceMap from '../../data/fr-departments.json';

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/france/fr-departments.json"

export default function DraftMap() {
  return (
    <ComposableMap>
      <Geographies geography={franceMap}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
    </ComposableMap>
  )
}
