import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { selectStyles, selectInputGroupStyles } from './selectStyles';
import { CustomOption, CustomOptionPeopleUnisex, CustomOptionPeopleMultisex, CustomSingleValue, CustomSingleValuePeopleUnisex, CustomSingleValuePeopleMultisex } from './selectComponents';


function UISelect({ label, placeholder, options, value, setValue, type, inputGroup, className, noPadding, disabled, searchable, isMulti }) {

    if (isMulti === undefined) {
        isMulti = false;
    }

    if (inputGroup) {
        return (<>
            <Select
                options={options}
                styles={selectInputGroupStyles}
                components={type === "countries" ? {} : type === "peoples_multisex" ? { Option: CustomOptionPeopleMultisex, SingleValue: CustomSingleValuePeopleMultisex } : type === "peoples" ? { Option: CustomOptionPeopleUnisex, SingleValue: CustomSingleValuePeopleUnisex } : { Option: CustomOption, SingleValue: CustomSingleValue }}
                placeholder={placeholder}
                value={value}
                onChange={(option) => { setValue(option.value) }}
                isDisabled={disabled}
                isSearchable={searchable}
                isMulti={isMulti}
            />
        </>)
    }
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <Select
                options={options}
                styles={selectStyles}
                components={type === "countries" ? {} : type === "peoples_multisex" ? { Option: CustomOptionPeopleMultisex, SingleValue: CustomSingleValuePeopleMultisex } : type === "peoples" ? { Option: CustomOptionPeopleUnisex, SingleValue: CustomSingleValuePeopleUnisex } : { Option: CustomOption, SingleValue: CustomSingleValue }}
                placeholder={placeholder}
                value={value}
                onChange={(option) => {
                    if (!isMulti) {
                        setValue(option.value)
                    } else {
                        let values = [];
                        option.map((a_option) => values.push(a_option.value));
                        setValue(values);
                    }
                }}
                isDisabled={disabled}
                isSearchable={searchable}
                isMulti={isMulti}
            />
        </Form.Group>
    </>)
}

export default UISelect;