import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { Form, Button } from "react-bootstrap";

import useAppStore from './stores/AppStore';
import useGenealogyStore from './stores/GenealogyStore';



import AppWrapper from './components/AppWrapper';

import PageList from './pages/PageList';
import PageNew from './pages/PageNew';
import PageStats from './pages/PageStats';
import PageView from './pages/PageView';
import PageTree from './pages/PageTree';
import PagePhotos from './pages/PagePhotos';
import PageEdit from './pages/PageEdit';

import MenuApp from './menus/MenuApp';

import './i18n';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-dark-5/dist/css/bootstrap-nightfall.min.css';
import "./index.scss";
import PageHome from './pages/PageHome';

function App() {


    const {
        checkApp,
        appIsLoading, appIsReady, appIsInit, appIsAuthenticated, appIsPrivate, appIsPasswordAuthenticated,
        appName, appTheme,
        userLogin, userLoginError, userIsLogged,
        userAuthorizeError, userIsAuthorized,
        checkPassword
    } = useAppStore();
    const { initData } = useGenealogyStore();

    const [enteredPassword, setEnteredPassword] = useState("");
    const [loginData, setLoginData] = useState({
        login: '',
        password: '',
    });

    useEffect(() => {
        checkApp();
    }, []);

    useEffect(() => {
        if (appIsReady) {
            initData();
        }
    }, [appIsReady]);

    if (appIsLoading) {
        return (
            <div className="App-loader">
                <RingLoader color='#FFF' size={100} />
                <p className="mt-3">Chargement</p>
            </div>
        )
    }

    if (!appIsInit) {
        return (
            <div className={`App-error${appTheme === "dark" ? ' theme-dark' : ''}`}>
                <span>🚀</span>
                <p>Prochainement ...</p>
            </div>
        );
    }

    if (appIsAuthenticated && !userIsLogged) {
        return (
            <div className={`App-error${appTheme === "dark" ? ' theme-dark' : ''}`}>
                <h1 className='text-primary'>{appName}</h1>
                <Form className="form-login" autoComplete="off" onSubmit={(e) => { e.preventDefault(); userLogin(loginData); }}>
                    <Form.Group>
                        <Form.Label>Adresse e-mail</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Votre adresse e-mail"
                            value={loginData.login}
                            onChange={(e) => setLoginData({ ...loginData, login: e.target.value })}
                            isInvalid={userLoginError === "no_login" || userLoginError === "bad_login"}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Votre mot de passe"
                            value={loginData.password}
                            onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                            isInvalid={userLoginError === "bad_key"}
                        />
                    </Form.Group>
                    <div className="d-grid mt-4">
                        <Button
                            type="submit"
                            variant="primary"
                        >
                            Se connecter
                            {/* <Icon name="right-to-bracket" className="ms-3" /> */}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }

    if (!appIsAuthenticated && appIsPrivate && !userIsAuthorized) {
        return (
            <div className={`App-error${appTheme === "dark" ? ' theme-dark' : ''}`}>
                <span>🔐</span>
                <p>Contenu privé</p>
                {appIsPasswordAuthenticated && <>
                    <Form style={{ width: 300 }} onSubmit={(e) => { e.preventDefault(); checkPassword(enteredPassword) }}>
                        <Form.Control type="password" placeholder="Entrez le mot de passe"
                            value={enteredPassword}
                            onChange={(e) => setEnteredPassword(e.target.value)}
                            isInvalid={userAuthorizeError === "bad_password"}
                        />
                        <Button type="submit" variant={appTheme === 'dark' ? 'dark' : 'light'} style={{ width: 300, marginTop: 10 }}>Valider</Button>
                    </Form>
                </>}
            </div>
        );
    }


    return (
        <AppWrapper>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<PageList />} />
                    <Route path="/peoples" element={<PageList />} />
                    <Route path="/people/new" element={<PageNew />} />
                    <Route path="/people/:id/edit" element={<PageEdit />} />
                    <Route path="/people/:id/view" element={<PageView />} />
                    <Route path="/tree" element={<PageTree />} />
                    <Route path="/stats" element={<PageStats />} />
                    <Route path="/photos" element={<PagePhotos />} />
                </Routes>
            </BrowserRouter>
            <MenuApp />
        </AppWrapper>
    );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);