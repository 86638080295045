import { Row, Col, Button } from "react-bootstrap";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";

// UI
import UIFormFieldset from "../../ui/UIFormFieldset";
import Icon from "../../ui/Icon";

// Components
import FormGroupEvent from "./FormGroupEvent";

export default function TabEvents() {

    const { peopleData, updatePeopleData } = useGenealogyStore();
    let eventIndex = 0;

    return (<>
        <UIFormFieldset legend="Événements" icon="calendar-lines">
            <Row>
                <Col>
                    {peopleData.events.map((event) => {
                        eventIndex++;
                        return (
                            <FormGroupEvent
                                key={eventIndex}
                                eventIndex={eventIndex}
                                eventData={event}
                            />
                        )
                    })}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid px-2 mt-2">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                let newArray = [...peopleData.events];
                                // if (newArray.pop().id !== '') {
                                let newEvent = {
                                    id: '',
                                    type: '',
                                    data1: '',
                                    data2: '',
                                    start_date: '',
                                    start_year: '',
                                    end_date: '',
                                    end_year: '',
                                    country: '',
                                    city: '',
                                    zipcode: '',
                                    document: '',
                                    to_save: '1'
                                };
                                updatePeopleData('events', [...peopleData.events, newEvent]);
                                // }
                            }}
                        >
                            <Icon name="plus" className="me-2" />
                            Ajouter un événement
                        </Button>
                    </div>
                </Col>
            </Row>
        </UIFormFieldset>
    </>)
}