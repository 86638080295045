// React
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import { readPictures } from '../../services/genealogy';

// Contexts
import useAppStore from '../../stores/AppStore';
import useGenealogyStore from '../../stores/GenealogyStore';

// Components
import AppHeader from '../../components/AppHeader';
import AppNav from '../../components/AppNav';

import ModalPicture from '../../modals/modalPicture';

// UI
import Icon from '../../ui/Icon';
import UIMasonry from '../../ui/UIMasonry';



function PagePhotos({ }) {

	const { t } = useTranslation();

	const { showModal } = useAppStore();
	const { picturesData, picturesIsFetched, fetchPicture } = useGenealogyStore();

	const [showedModalPicture, setShowedModalPicture] = useState(false);

	const [editedPicture, setEditedPicture] = useState(null);

	// useEffect(() => {
	// 	readPictures()
	// 	.then((response) => {
	// 		setPictures(response);
	// 	});
	// },[]);

	return (
		<>
			<div className='App'>
				<AppHeader isHome />
				<AppNav />
				<section className="App-section">
					<Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
						<h2 className='text-start'><Icon name="images" className="me-3" />{t('page_title_gallery_photo')}</h2>
						<Button
							variant='primary'
							className='ms-auto'
							onClick={() => showModal('picture', 'add')}
						>
							<Icon name="plus" className="me-2" />Nouvelle photo
						</Button>
					</Stack>
					{picturesIsFetched && <div className="picture-gallerdy">
						<UIMasonry cols={7}>
							{picturesData.map((picture) => (
								<img
									key={picture.id}
									src={`//${picture.url}`}
									onClick={() => {
										fetchPicture(picture.id);
										showModal('picture', 'edit');
									}}
									className="is-clickable"
								/>
							))}
						</UIMasonry>
					</div>}
				</section>
				<ModalPicture show={showedModalPicture} handleClose={() => { setShowedModalPicture(false); setEditedPicture(null); }} editedPicture={editedPicture} />
			</div>
		</>
	);
}

export default PagePhotos;