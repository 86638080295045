
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Card, Tab, Stack, Row, Col, Nav } from "react-bootstrap";

import useAppStore from "../../stores/AppStore";
import useGenealogyStore from "../../stores/GenealogyStore";

import AppHeader from "../../components/AppHeader";
import AppNav from "../../components/AppNav";



import UIIcon from "../../ui/UIIcon";
import UIButton from "../../ui/UIButton";
import TabIdentity from "./TabIdentity";
import TabData from "./TabData";
import TabTimeline from "./TabTimeline";
import TabPhotos from "./TabPhotos";
import TabFamily from "./TabFamily";

import './styles.scss';
import TabInfos from "./TabInfos";

export default function PageView() {

    const navigate = useNavigate();
    const params = useParams();

    const [isFirstTabActive, setIsFirstTabActive] = useState(true);
    const [isLastTabActive, setIsLastTabActive] = useState(false);

    const {
        peopleViewed, peopleLastViewed, peopleData,
        peoplesIsFetched, peopleIsFetched,
        fetchPeople, 
    } = useGenealogyStore();

    const {
        lastPage, lastTab, 
        setLastPage, setLastTab
    } = useAppStore();

    useEffect(() => {
        setLastPage("PageView");
    }, []);

    useEffect(() => {
        if (peoplesIsFetched) {
            fetchPeople(params.id);
        }
    }, [peoplesIsFetched, params.id]);


    return (<>
        <div className='App'>
            <AppHeader />
            <AppNav />
            <section className="App-section">
                <Stack direction="horizontal" className="mb-1 flex-column flex-sm-row App-section-header" style={{ borderBottomColor: 'transparent' }}>
                    <h2><UIIcon name="id-card" className="me-3" />Fiche de la personne</h2>
                    <UIButton
                        variant="outline-secondary"
                        icon="pencil"
                        label="Modifier la personne"
                        onClick={() => navigate('/people/' + peopleViewed + '/edit')}
                        className="ms-auto"
                    />
                </Stack>
                {peopleIsFetched && <>

                    <Tab.Container 
                        defaultActiveKey={lastPage === "PageView" && peopleViewed === peopleLastViewed && lastTab ? lastTab : 'identity'}
                        onSelect={(t) => {
                            if (t === 'identity') {
                                setIsFirstTabActive(true)
                            }
                            else {
                                setIsFirstTabActive(false);
                            }
                            if (t === 'data') {
                                setIsLastTabActive(true)
                            }
                            else {
                                setIsLastTabActive(false);
                            }
                            setLastTab(t);
                        }}
                    >
                        <Row className="">
                            {/* <Col sm={2}>
                                <h3 className="text-start font-name mb-0">{peopleData.name_last} {peopleData.name_first}</h3>
                            </Col> */}
                            <Col className="px-0">
                                <Nav variant="" fill>
                                    <Nav.Link eventKey="identity"><UIIcon name="id-card" className="me-2" />Identité</Nav.Link>
                                    <Nav.Link eventKey="family"><UIIcon name="family" className="me-2" />Famille</Nav.Link>
                                    {peopleData.events.length > 0 && <Nav.Link eventKey="timeline"><UIIcon name="calendar-star" className="me-2" />Timeline</Nav.Link>}
                                    {peopleData.pictures.length > 0 && <Nav.Link eventKey="photos"><UIIcon name="images" className="me-2" />Photos</Nav.Link>}
                                    <Nav.Link eventKey="infos"><UIIcon name="info-circle" className="me-2" />Informations</Nav.Link>
                                    <Nav.Link eventKey="data"><UIIcon name="database" className="me-2" />Données</Nav.Link>
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Card className={`card-people px-0${isFirstTabActive ? ` first-tab-active` : ``}${isLastTabActive ? ` last-tab-active` : ``}`} bg="dark">
                                <Card.Body>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="identity">
                                            <TabIdentity />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="family">
                                            <TabFamily />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="timeline">
                                            <TabTimeline />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="photos">
                                            <TabPhotos />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="infos">
                                            <TabInfos />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="data">
                                            <TabData />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Tab.Container>

                </>}
            </section>
        </div>
    </>)
}