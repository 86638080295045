// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'react-bootstrap';
import RingLoader from "react-spinners/RingLoader";

// Stores
import useAppStore from '../../stores/AppStore';
import useGenealogyStore from '../../stores/GenealogyStore';

// Components
import AppHeader from '../../components/AppHeader';
import AppNav from '../../components/AppNav';
import PeoplesTable from '../../components/PeoplesTable';

// UI
import UIIcon from '../../ui/UIIcon';

import UIFieldText from '../../ui/UIFieldText';
import UISwitcher from '../../ui/UISwitcher';

function PageList() {

	const { t } = useTranslation();
	const navigate = useNavigate();

	const { appThemeColor, setLastPage } = useAppStore();
	const { peoplesIsFetched, peoplesSearch, setPeoplesSearch, peoplesListed, peoplesFilters, setPeoplesFilters } = useGenealogyStore();

	const [showFilters, setShowFilters] = useState(false);

	useEffect(() => {
		setLastPage("peopleList");
	}, []);

	return (
		<>
			<div className='App'>
				<AppHeader isHome />
				<AppNav />
				<section className="App-section">
					{!peoplesIsFetched && <div className='loader'>
						<RingLoader color={appThemeColor} size={100} />
					</div>}
					{peoplesIsFetched && <>
						<div className='d-flex justify-content-between'>
							<div style={{ width: 340 }} className="d-flex flex-column-reverse">
								<div className='mb-4 d-flex'>
									<UIFieldText
										placeholder="Recherche..."
										setValue={(v) => setPeoplesSearch(v)}
										value={peoplesSearch}
										noGroups
									/>
									<Button className='ms-3' variant='secondary' onClick={() => setShowFilters(!showFilters)}><UIIcon name="filter" /></Button>
								</div>
							</div>
							<div>
								<span style={{ fontSize: '1.3em' }}><h2 className='text-center mt-2 mb-1'><UIIcon name="people-pants" className="me-3" />{t('page_title_peoples_list')}</h2></span>
								<p className='mb-4'>{peoplesListed.length} personnes</p>
							</div>
							<div style={{ width: 300 }} className="text-end d-flex flex-column-reverse">
								<Button variant='primary' className='ms-auto mb-4' onClick={() => navigate('/people/new')}>
									<UIIcon fixedWidth name="plus" className="me-2" />
									Nouvelle personne
								</Button>
							</div>
						</div>
						{showFilters && <div className='d-flex mb-3'>
							<div>
								<ButtonGroup>
								<Button 
										variant='outline-primary'
										onClick={(v) => setPeoplesFilters([...peoplesFilters.filter((filter) => filter.field !== 'is_living')])}
										active={peoplesFilters.filter((filter) => filter.field === 'is_living').length < 1}
									>Toutes les personnes</Button>
									<Button 
										variant='outline-primary'
										onClick={(v) => setPeoplesFilters([...peoplesFilters.filter((filter) => filter.field !== 'is_living'), {field:"is_living",value: '1'}])}
										active={peoplesFilters.filter((filter) => filter.field === 'is_living' && filter.value === '1').length >= 1}
									>Personne vivante</Button>
									<Button 
										variant='outline-primary'
										onClick={(v) => setPeoplesFilters([...peoplesFilters.filter((filter) => filter.field !== 'is_living'), {field:"is_living",value: '0'}])}
										active={peoplesFilters.filter((filter) => filter.field === 'is_living' && filter.value === '0').length >= 1}
									>Personne décédée</Button>
								</ButtonGroup>
								{/* <UISwitcher
									label="Personne vivante"
									setValue={(v) => setPeoplesFilters([...peoplesFilters.filter((filter) => filter.field !== 'is_living'), {field:"is_living",value: v ? '1' : '0'}])}
								/> */}
							</div>
							<div>
								{/* <Form.Range
									min={17} max={21} step={1}
									value={[17,18,19]}
								/> */}
							</div>
						</div>}

						<PeoplesTable />
					</>}
				</section>
			</div>
		</>
	);
}

export default PageList;