import { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";

import { eventTypesOptions, schoolTypesOptions, monthsSelectOptions } from "../../libs/constants";
import { getCountriesOptions } from "../../libs/utils";
import { fetchCity } from "../../services/genealogy";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";

// UI
import UIFieldText from "../../ui/UIFieldText";
import UIFieldNumber from "../../ui/UIFieldNumber";
import UISelect from "../../ui/UISelect";
import UIFieldAutocomplete from "../../ui/UIFieldAutocomplete";
import UIButton from "../../ui/UIButton";

export default function FormGroupEvent({ eventIndex, eventData }) {

    const { peopleData, updatePeopleData, fetchCity } = useGenealogyStore();

    const countriesOptions = getCountriesOptions();

    const [citiesOptions, setCitiesOptions] = useState([]);

    useEffect(() => {
        if (eventData.city !== "") {
            fetchCity(eventData.city).then((response) => {
                setCitiesOptions(response);
            });
        }
    }, [eventData]);

    return (<>
        <div key={eventIndex} className="item-events">
            <Row>
                <Col>
                    <Form.Label className="px-2 text-muted">Événement n°{eventIndex}</Form.Label>
                </Col>
                <Col className="text-end">
                    <UIButton
                        size="xs"
                        variant="outline-danger"
                        className="me-2"
                        onClick={() => {
                            let newEvents = peopleData.events;
                            if (eventData.to_save === '1') {
                                newEvents = newEvents.filter((event) => !event.to_save);
                            }
                            else {
                                newEvents = newEvents.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.to_delete = '1';
                                    }
                                    return a_event;
                                });
                            }
                            updatePeopleData("events", newEvents);
                        }}
                        icon="trash"
                        label="Supprimer l'événement"
                    />

                </Col>
            </Row>
            <Row>
                <Col>
                    <UISelect
                        placeholder="Choisissez le type d'événement"
                        options={eventTypesOptions}
                        value={eventTypesOptions.filter((a_event) => a_event.value === eventData.type)}
                        setValue={(v) => {
                            let newEvents = peopleData.events.map((a_event) => {
                                if (a_event.id === eventData.id) {
                                    a_event.type = v;
                                }
                                return a_event;
                            });
                            updatePeopleData("events", newEvents);
                        }}
                    />
                </Col>
            </Row>
            {eventData.type === "school" && <>
                <Row className="mt-4 mb-4">
                    <Col>
                        <UISelect
                            placeholder="Choisissez le type d'école"
                            options={schoolTypesOptions}
                            value={schoolTypesOptions.filter((a_event) => a_event.value === eventData.data2)}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.data2 = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                    <Col>
                        <UIFieldText
                            placeholder="Entrez le nom de l'école"
                            value={eventData.data1}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.data1 = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                </Row>
            </>}
            {eventData.type === "diploms" && <>
                <Row className="mt-4 mb-4">
                    <Col>
                        <UIFieldText
                            placeholder="Entrez le type du diplôme"
                            value={eventData.data1}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.data1 = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                    <Col>
                        <UIFieldText
                            placeholder="Entrez le niveau du diplôme"
                            value={eventData.data2}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.data2 = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                </Row>
            </>}
            {eventData.type === "work" && <>
                <Row className="mt-4 mb-4">
                    <Col>
                        <UIFieldText
                            placeholder="Entrez le nom du métier"
                            value={eventData.data1}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.data1 = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                    <Col>
                        <UIFieldText
                            placeholder="Entrez le nom de l'entreprise"
                            value={eventData.data2}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.data2 = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                </Row>
            </>}
            {eventData.type !== "" &&
                <Row className='mt-4'>
                    <Col>
                        <UISelect
                            placeholder="Sélectionnez le mois de début"
                            options={monthsSelectOptions}
                            value={monthsSelectOptions.filter((option) => option.value == eventData.start_date)}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.start_date = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                    <Col>
                        <UIFieldNumber
                            placeholder="Entrez l'année de début"
                            value={eventData.start_year}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.start_year = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                    {eventData.type !== "diploms" && eventData.type !== 'relocation' && eventData.type !== "retreat" && <>
                        <Col>
                            <UISelect
                                placeholder="Sélectionnez le mois de fin"
                                options={monthsSelectOptions}
                                value={monthsSelectOptions.filter((option) => option.value == eventData.end_date)}
                                setValue={(v) => {
                                    let newEvents = peopleData.events.map((a_event) => {
                                        if (a_event.id === eventData.id) {
                                            a_event.end_date = v;
                                        }
                                        return a_event;
                                    });
                                    updatePeopleData("events", newEvents);
                                }}
                            />
                        </Col>
                        <Col>
                            <UIFieldNumber
                                placeholder="Entrez l'année de fin"
                                value={eventData.end_year}
                                setValue={(v) => {
                                    let newEvents = peopleData.events.map((a_event) => {
                                        if (a_event.id === eventData.id) {
                                            a_event.end_year = v;
                                        }
                                        return a_event;
                                    });
                                    updatePeopleData("events", newEvents);
                                }}
                            />
                        </Col>
                    </>}
                </Row>
            }
            {eventData.type !== "diploms" && eventData.type !== "retreat" && eventData.type !== "" &&
                < Row className="mt-4">
                    <Col>
                        <UISelect
                            placeholder="Sélectionnez le pays de l'événement" type="countries"
                            options={countriesOptions}
                            value={countriesOptions.filter((option) => option.value == eventData.country)}
                            setValue={(v) => {
                                let newEvents = peopleData.events.map((a_event) => {
                                    if (a_event.id === eventData.id) {
                                        a_event.country = v;
                                    }
                                    return a_event;
                                });
                                updatePeopleData("events", newEvents);
                            }}
                        />
                    </Col>
                    {eventData.type !== "travel" && <>
                        <Col>
                            <UIFieldAutocomplete
                                placeholder="Entrez la ville de l'événement"
                                options={citiesOptions}
                                onSearch={(query) => {
                                    fetchCity(query).then((response) => {
                                        setCitiesOptions(response);
                                    })
                                }}
                                value={eventData.city}
                                setValue={(v, o) => {
                                    let newEvents = peopleData.events.map((a_event) => {
                                        if (a_event.id === eventData.id) {
                                            a_event.city = v;
                                            if (o.zipcode !== undefined) {
                                                a_event.zipcode = o.zipcode;
                                            }
                                        }
                                        return a_event;
                                    });
                                    updatePeopleData("events", newEvents);
                                }}
                            />
                        </Col>
                        <Col>
                            <UIFieldText
                                placeholder="Entrez le code postal de la ville de l'événement"
                                value={eventData.zipcode}
                                setValue={(v) => {
                                    let newEvents = peopleData.events.map((a_event) => {
                                        if (a_event.id === eventData.id) {
                                            a_event.zipcode = v;
                                        }
                                        return a_event;
                                    });
                                    updatePeopleData("events", newEvents);
                                }}
                            />
                        </Col>
                    </>}
                </Row>
            }
        </div>
    </>)

}