import { useCallback, useState } from "react";

export const useCenteredTree = (treeOrientation) => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: treeOrientation === "horizontal" ? 150 : width / 2, y: treeOrientation === "horizontal" ? height / 2 : 150 });
    }
  }, []);
  return [translate, containerRef];
};
