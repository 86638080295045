import { Col, Row } from "react-bootstrap";
import useGenealogyStore from "../../stores/GenealogyStore";

import UIMasonry from '../../ui/UIMasonry';

export default function TabPhotos() {

    const { peopleData } = useGenealogyStore();
    return (<>
        <Row>
            <Col>
                <UIMasonry cols={6}>
                    {peopleData.pictures.map((picture) => (
                        <img src={`https://${picture.url}`} />
                    ))}
                </UIMasonry>
            </Col>
        </Row>
    </>)
}