import React, { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";

// Constants
import { monthsSelectOptions, unionTypesOptions } from "../../libs/constants";
import { getCountriesOptions } from "../../libs/utils";

import { fetchCity } from "../../services/genealogy";

// UI
import UIFormFieldset from "../../ui/UIFormFieldset";
import UIFieldText from "../../ui/UIFieldText";
import UIFieldNumber from "../../ui/UIFieldNumber";
import UISelect from "../../ui/UISelect";
import UIFieldAutocomplete from "../../ui/UIFieldAutocomplete";
import Icon from "../../ui/Icon";
import UISwitcher from "../../ui/UISwitcher";
import FormGroupChild from "./FormGroupChild";

function TabFamily() {

    const { peopleData, updatePeopleData, peopleViewed, peoplesOptions } = useGenealogyStore();

    let child_index = 0;
    let union_index = 0;

    const countriesOptions = getCountriesOptions();

    const [unionStartCitiesOptions, setUnionStartCitiesOptions] = useState([]);
    const [unionEndCitiesOptions, setUnionEndCitiesOptions] = useState([]);

    useEffect(() => {
        peopleData.unions.forEach((union) => {
            if (union.start_city !== "") {
                fetchCity(union.start_city).then((response) => {
                    const oldOptions = unionStartCitiesOptions;
                    const newOptions = {
                        union: union.id,
                        options: response
                    }
                    setUnionStartCitiesOptions([...oldOptions, newOptions]);
                });
            }
            if (union.end_city !== "") {
                fetchCity(union.end_city).then((response) => {
                    const oldOptions = unionEndCitiesOptions;
                    const newOptions = {
                        union: union.id,
                        options: response
                    }
                    setUnionEndCitiesOptions([...oldOptions, newOptions]);
                });
            }

        });
    }, [peopleData]);

    return (<>
        <UIFormFieldset legend="Parents" icon="people-simple">
            <Row>
                <Col>
                    <UISelect
                        label="Mère"
                        placeholder="Sélectionnez la mère de la personne"
                        options={peoplesOptions.filter((option) => option.sex === "female" && option.value !== peopleViewed)}
                        value={peoplesOptions.filter((option) => option.value === peopleData.mother_id)}
                        setValue={(v) => updatePeopleData('mother_id', v)}
                        type="peoples"
                    />
                </Col>
                <Col>
                    <UISelect
                        label="Père"
                        placeholder="Sélectionnez le père de la personne"
                        options={peoplesOptions.filter((option) => option.sex === "male" && option.value !== peopleViewed)}
                        value={peoplesOptions.filter((option) => option.value === peopleData.father_id)}
                        setValue={(v) => updatePeopleData('father_id', v)}
                        type="peoples"
                    />
                </Col>
            </Row>
        </UIFormFieldset>
        <UIFormFieldset legend="Enfants" icon="child">
            <Row>
                <Col>
                    {peopleData.childrens_ids.map((child_id) => {
                        child_index++;
                        return (
                            <FormGroupChild
                                key={child_index}
                                childIndex={child_index}
                                childID={child_id}
                            />)
                    })}
                    <div className="d-grid px-2 mt-4">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                let newArray = [...peopleData.childrens_ids];
                                if (newArray.pop() !== '0') {
                                    updatePeopleData('childrens_ids', [...peopleData.childrens_ids, '0']);
                                }

                            }}
                            disabled={peopleData.childrens_ids.filter((child_id) => child_id === '0').length > 0}
                        >
                            <Icon name="plus" className="me-2" />
                            Ajouter un enfant
                        </Button>
                    </div>
                </Col>
            </Row>
        </UIFormFieldset>
        <UIFormFieldset legend="Unions" icon="rings-wedding">
            <Row>
                <Col>
                    {peopleData.unions.filter((union) => union.to_delete !== '1').map((union) => {
                        union_index++;
                        return (<div className="item-union" key={union.id}>
                            <Row>
                                <Col>
                                    <Form.Label className="px-2 text-muted">Union n°{union_index}</Form.Label>
                                </Col>
                                <Col className="text-end">
                                    <Button
                                        size="sm"
                                        variant="outline-danger"
                                        className="btn-xs me-2"
                                        onClick={() => {
                                            let newUnions = peopleData.unions;
                                            if (union.to_save === '1') {
                                                newUnions = newUnions.filter((union) => !union.to_save);
                                            }
                                            else {
                                                newUnions = newUnions.map((a_union) => {
                                                    if (a_union.id === union.id) {
                                                        a_union.to_delete = '1';
                                                    }
                                                    return a_union;
                                                });
                                            }
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    >
                                        <Icon name="trash" className="me-2" />
                                        Supprimer l'union
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <UISelect
                                        placeholder="Choisissez le partenaire de l'union"
                                        options={peoplesOptions.filter((option) => option.value !== peopleViewed)}
                                        value={peoplesOptions.filter((option) => option.value === union.partner_id)}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.partner_id = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                        type="peoples_multisex"
                                        disabled={union.id !== ""}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <UISelect
                                        placeholder="Choisissez le type d'union"
                                        options={unionTypesOptions}
                                        value={unionTypesOptions.filter((option) => option.value === union.type)}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.type = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col>
                                    <UIFieldNumber
                                        type="number"
                                        placeholder="Entrez le jour de début de l'union"
                                        value={union.start_date.split("-").pop()}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.start_date = union.start_date.split("-").shift() + '-' + v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                        min={1} max={31}
                                    />
                                </Col>
                                <Col>
                                    <UISelect
                                        placeholder="Sélectionnez le mois de début de l'union"
                                        options={monthsSelectOptions}
                                        value={monthsSelectOptions.filter((option) => option.value == union.start_date.split("-").shift())}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.start_date = v + '-' + union.start_date.split("-").pop();
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <UIFieldNumber
                                        type="number"
                                        placeholder="Entrez l'année de début de l'union"
                                        value={union.start_year}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.start_year = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col>
                                    <UISelect
                                        placeholder="Sélectionnez le pays de début de l'union" type="countries"
                                        options={countriesOptions}
                                        value={countriesOptions.filter((option) => option.value == union.start_country)}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.start_country = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <UIFieldAutocomplete
                                        placeholder="Entrez la ville de début de l'union"
                                        options={unionStartCitiesOptions.filter((options) => options.union === union.id).length < 1 ? [] : unionStartCitiesOptions.filter((options) => options.union === union.id).shift().options}
                                        onSearch={(query) => {
                                            fetchCity(query).then((response) => {
                                                const newOptions = unionStartCitiesOptions.map((options) => {
                                                    if (options.union === union.id) {
                                                        options.options = response;
                                                    }
                                                    return options;
                                                });
                                                setUnionStartCitiesOptions(newOptions);
                                            })
                                        }}
                                        value={union.start_city}
                                        setValue={(v, o) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.start_city = v;
                                                    if (o.zipcode !== undefined) {
                                                        a_union.start_zipcode = o.zipcode;
                                                    }
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <UIFieldText
                                        placeholder="Entrez le code postal de la ville de début de l'union"
                                        value={union.start_zipcode}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.start_zipcode = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <UISwitcher
                                        label="Union terminée"
                                        value={union.is_active === '0'}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.is_active = v ? '0' : '1';
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                            </Row>
                            {union.is_active === '0' && <Row className='mb-4 mt-4'>
                                <Col>
                                    <UIFieldNumber
                                        type="number"
                                        placeholder="Entrez le jour de fin de l'union"
                                        value={union.end_date.split("-").pop()}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.end_date = union.end_date.split("-").shift() + '-' + v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                        min={1} max={31}
                                    />
                                </Col>
                                <Col>
                                    <UISelect
                                        placeholder="Sélectionnez le mois de fin de l'union"
                                        options={monthsSelectOptions}
                                        value={monthsSelectOptions.filter((option) => option.value == union.end_date.split("-").shift())}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.end_date = v + '-' + union.end_date.split("-").pop();
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <UIFieldNumber
                                        type="number"
                                        placeholder="Entrez l'année de fin de l'union"
                                        value={union.end_year}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.end_year = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                            </Row>}
                            {union.is_active === '0' && <Row>
                                <Col>
                                    <UISelect
                                        placeholder="Sélectionnez le pays de fin de l'union" type="countries"
                                        options={countriesOptions}
                                        value={countriesOptions.filter((option) => option.value == union.end_country)}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.end_country = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <UIFieldAutocomplete
                                        placeholder="Entrez la ville de fin de l'union"
                                        options={unionEndCitiesOptions.filter((options) => options.union === union.id).length < 1 ? [] : unionEndCitiesOptions.filter((options) => options.union === union.id).shift().options}
                                        onSearch={(query) => {
                                            fetchCity(query).then((response) => {
                                                const newOptions = unionEndCitiesOptions.map((options) => {
                                                    if (options.union === union.id) {
                                                        options.options = response;
                                                    }
                                                    return options;
                                                });
                                                setUnionEndCitiesOptions(newOptions);
                                            })
                                        }}
                                        value={union.end_city}
                                        setValue={(v, o) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.end_city = v;
                                                    if (o.zipcode !== undefined) {
                                                        a_union.end_zipcode = o.zipcode;
                                                    }
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <UIFieldText
                                        placeholder="Entrez le code postal de la ville de fin de l'union"
                                        value={union.end_zipcode}
                                        setValue={(v) => {
                                            let newUnions = peopleData.unions.map((a_union) => {
                                                if (a_union.id === union.id) {
                                                    a_union.end_zipcode = v;
                                                }
                                                return a_union;
                                            });
                                            updatePeopleData("unions", newUnions);
                                        }}
                                    />
                                </Col>
                            </Row>}
                        </div>)
                    })}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid px-2 mt-1">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                let newArray = [...peopleData.unions];
                                // if (newArray.sfilter((a_union) => a_union.to_save === '1' && a_union.partner_id === '').length < 1) {
                                let newUnion = {
                                    id: '',
                                    partner_id: '',
                                    type: '',
                                    start_date: '',
                                    start_year: '',
                                    start_country: '',
                                    start_city: '',
                                    start_zipcode: '',
                                    is_active: '1',
                                    end_date: '',
                                    end_year: '',
                                    end_country: '',
                                    end_city: '',
                                    end_zipcode: '',
                                    document: '',
                                    to_save: '1'
                                };
                                updatePeopleData('unions', [...peopleData.unions, newUnion]);
                                // }
                            }}
                        // disabled={peopleData.unions.filter((a_union) => a_union.to_save === '1' && a_union.partner_id === '').length > 0}
                        >
                            <Icon name="plus" className="me-2" />
                            Ajouter une union
                        </Button>
                    </div>
                </Col>
            </Row>
        </UIFormFieldset>
    </>)
}

export default TabFamily;