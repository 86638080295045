import { Col, Row, Stack } from "react-bootstrap";
import PeoplePicture from "../../components/PeoplePicture";
import { formatBirthDate, formatDeathDate, formatPlaceLong, printZodiac } from "../../libs/utils.jsx";
import useGenealogyStore from "../../stores/GenealogyStore";
import UIIcon from "../../ui/UIIcon";

export default function TabIdentity() {

    const { peopleData } = useGenealogyStore();
    return (<>
        <Row className="mb-4">
            <Col>
                <Stack direction="horizontal" className="align-items-start">
                    <div>
                        <div className="people-picture me-3 ms-2">
                            <PeoplePicture peopleData={peopleData} size={80} />
                        </div>
                    </div>
                    <div>
                        <h3 className="font-name">{peopleData.name_last}</h3>
                        <h4 className="font-name">{peopleData.name_first} <span className="text-muted">{peopleData.name_second} {peopleData.name_third}</span></h4>
                    </div>
                    <div className="ms-auto align-top me-2">
                        {peopleData.is_living === '0' && <p className="lead text-muted"><UIIcon name="angel" className="me-2" />{`Décédé${peopleData.sex === "female" ? `é` : ''}`}{` à ${peopleData.death_age}ans`}</p>}
                    </div>
                </Stack>

            </Col>
        </Row>
        <Row>
            <Col>
                <h4 className="text-muted text-center"><UIIcon name="baby" className="me-2" />Naissance</h4>
                <p className="text-center mb-2">
                    <strong>{formatBirthDate(peopleData)}</strong><br />
                    {peopleData.birth_time ? `${peopleData.birth_time}` : `Heure inconnue`}
                </p>
                <p className="text-center">
                    {formatPlaceLong(peopleData.birth_place)}
                </p>
                <p className="text-center mt-5">
                    Signe astrologique : {peopleData.zodiac && printZodiac(peopleData.zodiac)}
                </p>
            </Col>
            {peopleData.is_living === '0' && <Col>
                <h4 className="text-muted text-center"><UIIcon name="coffin-cross" className="me-2" />Décès</h4>
                <p className="text-center mb-2">
                <strong>{formatDeathDate(peopleData)}</strong><br />
                    {peopleData.death_time ? `${peopleData.death_time}` : `Heure inconnue`}
                </p>
                <p className="text-center">
                    {formatPlaceLong(peopleData.death_place)}
                </p>
                <p className="text-center mt-5">
                    Âge : {peopleData.death_age}ans
                </p>
            </Col>}
        </Row>
    </>)
}