import UIIcon from "../ui/UIIcon";

export default function PeoplePicture({ peopleData, size }) {
    if (!size) {
        size = 40;
    }
    return (<div className='picture-wrapper text-center'>
        {peopleData.main_picture &&
            <img
                src={"https://" + peopleData.main_picture}
                style={{ maxWidth: size, maxHeight: size, borderRadius: '50%' }}
                className={peopleData.is_living === '0' ? 'angel' : ''}
                alt="people"
            />
        }
        {!peopleData.main_picture &&
            <div style={{ width: size, height: size, borderRadius: '50%', backgroundColor: '#222', fontSize: `${size - (size * .35)}px`, lineHeight: `${size}px`, color: peopleData.is_living === '0' ? '#666' : '#FFF' }}>
                <UIIcon name={peopleData.sex === "female" ? "person-dress" : "person"} />
            </div>}
    </div>)
}