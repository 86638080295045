import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Bootstrap
import { Stack, Form, Tabs, Tab } from "react-bootstrap";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";
import useAppStore from "../../stores/AppStore";

// Components
import AppHeader from "../../components/AppHeader";
import AppNav from "../../components/AppNav";

// Tabs
import TabIdentity from "./TabIdentity";
import TabData from "./TabData";
import TabLife from "./TabLife";
import TabFamily from "./TabFamily";
import TabNotes from "./TabNotes";
import TabDocuments from "./TabDocuments";
import TabEvents from "./TabEvents";
import TabPhotos from "./TabPhotos";
import TabBiology from "./TabBiology";

// UI
import UIIcon from "../../ui/UIIcon";
import UIButton from "../../ui/UIButton";

function PageEdit() {

    const navigate = useNavigate();
    const params = useParams();
    const {
        peoplesIsFetched, peopleIsFetched, peopleIsUpdating, peopleIsEdited,
        peopleViewed,
        fetchPeople, savePeopleData, resetPeopleData
    } = useGenealogyStore();

    const {
        lastPage, lastTab,
        setLastAction, setLastPage, setLastTab
    } = useAppStore();

    useEffect(() => {
        setLastPage("PageEdit");
    }, []);

    useEffect(() => {
        if (peoplesIsFetched) {
            fetchPeople(params.id);
        }
    }, [peoplesIsFetched, params.id]);



    const handleSubmit = (e) => {
        e.preventDefault();
        if (!peopleIsUpdating) {
            savePeopleData().then((result) => {
                // console.log(result);
                if (result) {
                    setLastAction("update_people");
                }
            });
        }
        return false;
    }

    return (<>
        <div className='App'>
            <AppHeader />
            <AppNav />
            {peopleIsFetched && <section className="App-section">
                <Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
                    <h2><UIIcon name="pencil" className="me-3" />Modifier une personne</h2>
                    <UIButton
                        variant="outline-secondary"
                        icon="id-card"
                        label="Voir la personne"
                        onClick={() => navigate('/people/' + peopleViewed + '/view')}
                        className="ms-auto"
                    />
                </Stack>
                <Form onSubmit={handleSubmit}>
                    <Tabs
                        defaultActiveKey={lastPage === "PageEdit" && lastTab ? lastTab : 'people'}
                        className="mb-2"
                        justify
                        variant="pills"
                        onSelect={(t) => setLastTab(t)}
                    >
                        <Tab eventKey="people" title={<><UIIcon name="id-card" className="me-2" />Identité</>}>
                            <TabIdentity />
                        </Tab>
                        <Tab eventKey="biology" title={<><UIIcon name="dna" className="me-2" />Biologie</>}>
                            <TabBiology />
                        </Tab>
                        <Tab eventKey="life" title={<><UIIcon name="wave-pulse" className="me-2" />Vie</>}>
                            <TabLife />
                        </Tab>
                        <Tab eventKey="family" title={<><UIIcon name="family" className="me-2" />Famille</>}>
                            <TabFamily />
                        </Tab>
                        <Tab eventKey="events" title={<><UIIcon name="calendar-star" className="me-2" />Événements</>}>
                            <TabEvents />
                        </Tab>
                        <Tab eventKey="photos" title={<><UIIcon name="images" className="me-2" />Photos</>}>
                            <TabPhotos />
                        </Tab>
                        <Tab eventKey="documents" title={<><UIIcon name="file-user" className="me-2" />Documents</>}>
                            <TabDocuments />
                        </Tab>
                        <Tab eventKey="notes" title={<><UIIcon name="text" className="me-2" />Notes</>}>
                            <TabNotes />
                        </Tab>
                        {/* <Tab eventKey="data" title={<><UIIcon name="database" className="me-2" />Données</>}>
                            <TabData />
                        </Tab> */}
                    </Tabs>
                    <Form.Group className="mt-4 pt-4 border-top d-grid">
                        <UIButton
                            type="submit"
                            size="lg"
                            icon="check"
                            label="Enregistrer"
                            disabled={!peopleIsEdited}
                            isWorking={peopleIsUpdating}
                        />
                    </Form.Group>
                    <Form.Group className="mt-4 d-grid">
                        <UIButton
                            size="lg"
                            variant="light"
                            icon="times"
                            label="Annuler"
                            disabled={!peopleIsEdited}
                            onClick={() => resetPeopleData()}
                        />
                    </Form.Group>
                </Form>
            </section>}
        </div>
    </>)
}

export default PageEdit;