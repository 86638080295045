import { components } from 'react-select';
import Icon from '../Icon';

export const CustomOption = ({ children, ...props }) => {

    if (props.data.flag !== undefined) {
        return (
            <components.Option {...props}>
                <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><span className="me-2" style={{ fontSize: '1.2em' }}>{props.data.flag}</span>
                    {children}</span>
            </components.Option>
        );
    }
    return (
        <components.Option {...props}>
            <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><Icon name={props.data.icon || ""} iconClass={props.data.iconClass || false} className="me-2" />
                {children}</span>
        </components.Option>
    );
};

export const CustomOptionFont = ({ children, ...props }) => {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <components.Option {...props}>
            <div className='d-flex'>
                <span>{children}</span>
                <small className='ms-auto text-muted'>{capitalizeFirstLetter(props.data.category)}</small>
            </div>
        </components.Option>
    );
};

export const CustomOptionPeopleUnisex = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <span className='d-flex'>
                <span>{children} <em className='text-gray'>{props.data.names}</em></span>
                <span className='ms-auto text-muted fw-300'>{props.data.dates}</span>
            </span>
        </components.Option>
    );
};

export const CustomOptionPeopleMultisex = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <span className='d-flex'>
                <span className={props.data.sex === 'female' ? "text-pink" : "text-blue"}><Icon name={props.data.sex === 'female' ? "venus" : "mars"} className="me-2" /></span>
                <span>{children} <em className='text-gray'>{props.data.names}</em></span>
                <span className='ms-auto text-muted fw-300'>{props.data.dates}</span>
            </span>
        </components.Option>
    );
};

export const CustomSingleValue = ({ children, ...props }) => {
    if (props.data.flag !== undefined) {
        return (
            <components.SingleValue {...props}>
                <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><span className="me-2" style={{ fontSize: '1.2em' }}>{props.data.flag}</span>
                    {children}</span>
            </components.SingleValue>
        );
    }
    return (
        <components.SingleValue {...props}>
            <span className={props.data.text_color ? "text-" + props.data.text_color : ""}><Icon name={props.data.icon || ""} iconClass={props.data.iconClass || false} className="me-2" />
                {children}
            </span>
        </components.SingleValue>
    );
};

export const CustomSingleValuePeopleUnisex = ({ children, ...props }) => {

    return (
        <components.SingleValue {...props}>
            <span className='d-flex'>
                <span>{children} <em className='text-gray'>{props.data.names}</em></span>
                <span className='ms-auto text-muted fw-300'>{props.data.dates}</span>
            </span>
        </components.SingleValue>
    );
};

export const CustomSingleValuePeopleMultisex = ({ children, ...props }) => {

    return (
        <components.SingleValue {...props}>
            <span className='d-flex'>
                <span className={props.data.sex === 'female' ? "text-pink" : "text-blue"}><Icon name={props.data.sex === 'female' ? "venus" : "mars"} className="me-2" /></span>
                <span>{children} <em className='text-gray'>{props.data.names}</em></span>
                <span className='ms-auto text-muted fw-300'>{props.data.dates}</span>
            </span>
        </components.SingleValue>
    );
};