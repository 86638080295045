import React from 'react';
import { useNavigate } from "react-router-dom";

import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { getPlaceTooltipContent } from '../libs/utils';

import Icon from '../ui/Icon.jsx';
import BasicTooltip from '../ui/Tooltip';

import { Button, ButtonGroup, Badge, Row, Col } from 'react-bootstrap';
import useGenealogyStore from '../stores/GenealogyStore';
import PeoplePicture from './PeoplePicture';

function PeopleRow({ people }) {

    const navigate = useNavigate();
    const { fetchTree } = useGenealogyStore();

    return (
        <tr>
            <td style={{ width: 40, height: 40, textAlign: "center" }}>
                <PeoplePicture peopleData={people} />
            </td>
            <td style={{ lineHeight: '40px', fontSize: 24, width: 60 }} className="text-center">{people.sex == "female" && <Icon name="venus" className="text-pink" />}{people.sex == "male" && <Icon name="mars" className="text-cyan" />}</td>
            <td style={{ lineHeight: '40px' }}>
                <span className="d-flex font-name">
                    <span>{people.name_last}</span>
                    <small className='ms-auto text-muted'>{people.name_wedding && <span> {people.name_wedding}</span>}</small>
                </span>
            </td>
            <td style={{ lineHeight: '40px' }}>
                <span className="d-flex font-name">
                    <span>{people.name_first}</span>
                    <small className='ms-auto text-muted'>{people.name_second && <span> {people.name_second}</span>}{people.name_third && <span> {people.name_third}</span>}</small>
                </span>
            </td>
            <td style={{ lineHeight: '20px', width: 250 }}>
                <span className="d-flex">
                    <span className="d-flex flex-column">
                        <p className='mb-0 font-date'>{people.birth_year ? people.birth_year : '?'}</p>
                        {people.birth_place !== undefined && <p className='mb-0 text-muted'>{people.birth_place.city !== undefined && people.birth_place.city !== "" ? <small className='me-2 cursor-help'><BasicTooltip content={getPlaceTooltipContent(people.birth_place)} placement="bottom">{people.birth_place.city}</BasicTooltip></small> : <small className='me-2'>?</small>}
                            {people.birth_place.country !== undefined && <small style={{ verticalAlign: "middle", fontSize: '1em' }}>{people.birth_place.country && getUnicodeFlagIcon(people.birth_place.country.toUpperCase())}</small>}
                        </p>}
                    </span>
                    {people.birth_certificate &&
                        <span className='ms-auto me-1' style={{ lineHeight: '40px', fontSize: '1.2em' }}>
                            <a href={`https://${people.birth_certificate}`} target="_blank"><BasicTooltip content="Acte de naissance" placement="right"><Icon name="file-certificate" /></BasicTooltip></a>
                        </span>}
                </span>
            </td>
            <td style={{ lineHeight: '20px', width: 250 }}>
                {people.is_living === '0' && < span className="d-flex">
                    <span className="d-flex flex-column">
                        <p className='mb-0 font-date'>{people.death_year ? people.death_year : '?'}</p>
                        {people.death_place !== undefined && <p className='mb-0 text-muted'>{people.death_place.city !== undefined && people.death_place.city !== "" ? <small className='me-2 cursor-help'><BasicTooltip content={getPlaceTooltipContent(people.death_place)} placement="bottom">{people.death_place.city}</BasicTooltip></small> : <small className='me-2'>?</small>}
                            {people.death_place.country !== undefined && <small style={{ verticalAlign: "middle", fontSize: '1em' }}>{people.death_place.country && getUnicodeFlagIcon(people.death_place.country.toUpperCase())}</small>}
                        </p>}
                    </span>
                    {people.death_certificate &&
                        <span className='ms-auto me-1' style={{ lineHeight: '40px', fontSize: '1.2em' }}>
                            <a href={`https://${people.death_certificate}`} target="_blank"><BasicTooltip content="Acte de décès" placement="right"><Icon name="file-certificate" /></BasicTooltip></a>
                        </span>}
                </span>}
                {people.is_living === '1' && <p className='text-center text-theme mb-0' style={{ lineHeight: '40px' }}>{people.sex === "female" ? "Vivante" : "Vivant"}</p>}
            </td>
            <td style={{ width: 110, lineHeight: '20px' }}>
                <Row>
                    <Col sm={6} className="pe-0 ps-2">
                        <p className='mb-0 text-theme'><Badge className="font-number" bg="dark" text="white">{people.unions.length}</Badge><Icon name="rings-wedding" fixedWidth /></p>
                        <p className='mb-0 text-theme'><Badge className="font-number" bg="dark" text="white">{people.childrens_ids.length}</Badge><Icon name="child" fixedWidth /></p>
                    </Col>
                    <Col sm={6} className="ps-0 pe-2">
                        <p className='mb-0 text-theme'><Badge className="font-number" bg="dark" text="white">{people.events.length}</Badge><Icon name="calendar-star" fixedWidth /></p>
                        <p className='mb-0 text-theme'><Badge className="font-number" bg="dark" text="white">{people.pictures.length}</Badge><Icon name="images" fixedWidth /></p>
                    </Col>
                </Row>

            </td>
            <td style={{ width: 110, lineHeight: '40px' }}>
                <ButtonGroup>
                    <Button variant={!people.mother_id && !people.father_id ? "outline-light" : "outline-light"} disabled={!people.mother_id && !people.father_id} onClick={() => { fetchTree(people.id, "ancestors"); navigate('/tree'); }}><Icon fixedWidth name="up-from-line" /></Button>
                    <Button variant={!people.childrens_ids.length ? "outline-light" : "outline-light"} disabled={!people.childrens_ids.length} onClick={() => { fetchTree(people.id, "descendants"); navigate('/tree'); }}><Icon fixedWidth name="down-from-line" /></Button>
                </ButtonGroup>
            </td>
            <td style={{ width: 110, lineHeight: '40px' }}>
                <ButtonGroup>
                    <Button variant="secondary" onClick={() => navigate('/people/' + people.id + '/view')}><Icon fixedWidth name="eye" /></Button>
                    <Button variant="primary" onClick={() => navigate('/people/' + people.id + '/edit')}><Icon fixedWidth name="pencil" /></Button>
                </ButtonGroup>
            </td>
        </tr>
    )


}

export default PeopleRow;