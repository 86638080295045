import AppHeader from "../../components/AppHeader"
import AppNav from "../../components/AppNav"
import { Stack, Row, Col } from "react-bootstrap"
import UIIcon from "../../ui/UIIcon"
import { VectorMap } from '@south-paw/react-vector-maps';

import france from '../../data/map-france.json'

import useGenealogyStore from "../../stores/GenealogyStore"
import { useEffect } from "react"

import DraftMap from "./DraftMap";

export default function PageStats({ }) {

    const { fetchStats } = useGenealogyStore();

    useEffect(() => {
        fetchStats();
    }, []);

    return (<>
        <div className='App'>
            <AppHeader />
            <AppNav />
            <section className="App-section">
                <Stack direction="horizontal" className="mb-3 flex-column flex-sm-column App-section-header">
                    <div>
                        <h2 className='text-center'><UIIcon name="chart-simple" className="me-3" />Statistiques</h2>
                    </div>
                </Stack>
                <Row>
                    <Col>
                        <div className="map">
                            <VectorMap {...france} />
                        </div>
                    </Col>
                    <Col> 
                        <div className="map">
                        <DraftMap />
                        </div>
                    </Col>
                </Row>
                
                
            </section>
        </div>
    </>)
}