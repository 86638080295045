import UIFormFieldset from "../../ui/UIFormFieldset";
import useGenealogyStore from "../../stores/GenealogyStore";
import UISelect from "../../ui/UISelect";
import { Row, Col } from 'react-bootstrap';

import { sexSelectOptions, bloodGroupOptions, bloodRhesusOptions } from "../../libs/constants";

export default function TabBiology() {

    const { peopleData, updatePeopleData } = useGenealogyStore();

    return (<>
        <UIFormFieldset legend="Sexe" icon="mars-and-venus">
            <Row>
                <Col>
                    <UISelect
                        options={sexSelectOptions}
                        placeholder="Sélectionnez le sexe"
                        value={sexSelectOptions.filter((option) => option.value == peopleData.sex)}
                        setValue={(v) => updatePeopleData('sex', v)}
                    />
                </Col>
            </Row>
        </UIFormFieldset>
        <UIFormFieldset legend="Sang" icon="vial">
            <Row>
                <Col>
                    <UISelect
                        label="Groupe sanguin"
                        placeholder="Choisissez le groupe sanguin"
                        options={bloodGroupOptions}
                        value={bloodGroupOptions.filter((group) => group.value === peopleData.blood_group)}
                        setValue={(v) => updatePeopleData('blood_group', v)}
                    />
                </Col>
                <Col>
                    <UISelect
                        label="Rhésus sanguin"
                        placeholder="Choisissez le rhésus sanguin"
                        options={bloodRhesusOptions}
                        value={bloodRhesusOptions.filter((group) => group.value === peopleData.blood_rhesus)}
                        setValue={(v) => updatePeopleData('blood_rhesus', v)}
                    />
                </Col>
            </Row>
        </UIFormFieldset>
    </>)
}