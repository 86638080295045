// React
import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Tree from 'react-d3-tree';
import { useCenteredTree } from '../../libs/helpers';

// Stores
import useGenealogyStore from '../../stores/GenealogyStore';

// Libs
import { fetchAncestors } from '../../libs/call';
import { setColors } from '../../libs/utils';

// UI
import Icon from '../../ui/Icon';

// Components
import AppHeader from '../../components/AppHeader';
import AppNav from '../../components/AppNav';
// import PeoplesTable from '../components/PeoplesTable';

// Bootstrap
import { ButtonGroup, Button, Stack, Nav } from 'react-bootstrap';
import { node } from 'prop-types';
import MenuTree from '../../menus/MenuTree';
import GraphTree from '../../graph/GraphTree';
import UIIcon from '../../ui/UIIcon';
import { useNavigate } from 'react-router-dom';

function PageTree() {

  const handleFullscreen = useFullScreenHandle();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { treeData, treeIsFetched, treeViewedDirection, fetchTree,
    treeSeparationSiblings, treeSeparationNonSiblings, treeZoom, treeDepthFactor } = useGenealogyStore();


  const [treeOrientation, setTreeOrientation] = useState('horizontal');
  let [translate, containerRef] = useCenteredTree(treeOrientation);

  const containerStyles = {
    width: "100%",
    height: "calc(100vh - 252px)"
  };

  const containerStylesFullscreen = {
    width: "100%",
    height: "100%"
  };

  useLayoutEffect(() => {
    setColors();
  });


  const nodeSize = { x: 110, y: 130 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 40 };

  const RenderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps
  }) => {
    const [isOpen, setIsOpen] = useState(false);;

    // console.log(nodeDatum);
    return ((
      <g>
        <circle
          r="15"
          fill={nodeDatum.children ? 'black' : nodeDatum.__rd3t.collapsed ? 'white' : nodeDatum.sex === "male" ? 'var(--bs-blue)' : 'var(--bs-pink)'}
          stroke={nodeDatum.sex === "male" ? 'var(--bs-blue)' : 'var(--bs-pink)'} strokeWidth='4'
          onClick={(e, v) => { console.log(e.target); setIsOpen(!isOpen) }}></circle>
        <g className="rd3t-label">
          <text
            className="rd3t-label__title font-name"
            textAnchor={nodeDatum.parent === "null" ? 'end' : nodeDatum.children ? 'middle' : 'start'}
            x={nodeDatum.parent === "null" ? '-40' : nodeDatum.children ? '0' : '40'}
            y={nodeDatum.parent === "null" ? '-10' : !nodeDatum.children ? '-10' : nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '45' : '-30'}
            onClick={() => { navigate('/people/' + nodeDatum.people_id + '/view')}}
          >
            {nodeDatum.name}
          </text>
          {nodeDatum.birth && <text
            className="rd3t-label__attributes"
            textAnchor={nodeDatum.parent === "null" ? 'end' : nodeDatum.children ? 'middle' : 'start'}
          >
            <tspan
              x={nodeDatum.parent === "null" ? '-40' : nodeDatum.children ? '0' : '40'}
              y={nodeDatum.parent === "null" ? '0' : !nodeDatum.children ? '0' : nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '53' : '-75'}
              dy="1.2em"
            >
              {nodeDatum.birth}
            </tspan>
          </text>}
          {isOpen && <text
            className="rd3t-label__attributes actions"
            textAnchor={nodeDatum.parent === "null" ? 'end' : nodeDatum.children ? 'middle' : 'middle'}
          >
            <tspan
              x={nodeDatum.parent === "null" ? '-40' : nodeDatum.children ? '20' : '20'}
              y={nodeDatum.parent === "null" ? '0' : !nodeDatum.children ? nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '15' : '-73' : nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '-73' : '15'}
              dy="1.2em"
              onClick={() => fetchTree(nodeDatum.people_id, 'ancestors')}
            >
              ↟
            </tspan>
            {/* <tspan
              x={nodeDatum.parent === "null" ? '-40' : nodeDatum.children ? '0' : '0'}
              y={nodeDatum.parent === "null" ? '0' : !nodeDatum.children ? nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '15' : '-73' : nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '-73' : '15'}
              dy="1.2em"
              onClick={() => fetchTree(nodeDatum.people_id, 'ancestors')}
            >
              Modifier
            </tspan> */}
            <tspan
              x={nodeDatum.parent === "null" ? '-40' : nodeDatum.children ? '-20' : '-20'}
              y={nodeDatum.parent === "null" ? '0' : !nodeDatum.children ? nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '15' : '-73' : nodeDatum.sex === "female" && treeViewedDirection === "ancestors" ? '-73' : '15'}
              dy="1.2em"
              onClick={() => fetchTree(nodeDatum.people_id, 'descendants')}
            >
              ↡
            </tspan>
          </text>}
        </g>
      </g>
    ))
  };



  return (
    <>
      <div className='App'>
        <AppHeader page="tree" />
        <AppNav />
        <section className="App-section" style={{ maxWidth: '100vw', paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
          <Button className='btn-fullscreen' variant="outline-secondary" size="sm" onClick={handleFullscreen.enter}>
            Plein écran<Icon name="expand-wide" className="ms-2" />
          </Button>
          <Stack direction="horizontal" className="mb-3 flex-column flex-sm-column App-section-header">
            <div>
              <h2 className='text-center'><Icon name="tree-deciduous" className="me-3" />{t('page_title_tree_view')}</h2>
            </div>
          </Stack>
          {/* <div className='text-center'>
          <ButtonGroup aria-label="Basic example">
            <Button variant={treeOrientation === "horizontal" ? "secondary" : "outline-secondary" } onClick={() => { setTreeOrientation("horizontal");  }}>Horizontal</Button>
            <Button variant={treeOrientation === "vertical" ? "secondary" : "outline-secondary" } onClick={() => { setTreeOrientation("vertical");  }}>Vertical</Button>
            
          </ButtonGroup>
          </div> */}


          {/* {treeIsFetched && <GraphTree width={1800} height={1000} data={treeData} />} */}

          <FullScreen handle={handleFullscreen}>
            <div style={handleFullscreen.active ? containerStylesFullscreen : containerStyles} ref={containerRef}>
              <Tree
                data={treeData}
                translate={translate}
                orientation={treeOrientation}
                nodeSize={nodeSize}
                renderCustomNodeElement={(rd3tProps) => {
                  // console.log(rd3tProps);
                  // renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                  return (
                    <RenderForeignObjectNode nodeDatum={rd3tProps.nodeDatum} toggleNode={rd3tProps.toggleNode} foreignObjectProps={foreignObjectProps} />
                  )
                }}
                zoomable={false}
                collapsible={false}
                depthFactor={treeDepthFactor}
                zoom={treeZoom}
                separation={{ nonSiblings: treeSeparationNonSiblings, siblings: treeSeparationSiblings }}
                pathFunc="step"
                onNodeClickr={(n, e) => { console.log(n); console.log(e); }}
              />
            </div></FullScreen>
        </section>
      </div>
      <MenuTree />
    </>
  );
}

export default PageTree;