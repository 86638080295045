import React, { useRef, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { uploadFile } from "../../services/cloud";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";

// UI
import UIFormFieldset from "../../ui/UIFormFieldset";
import UIFieldText from "../../ui/UIFieldText";
import UISelect from "../../ui/UISelect";

import { documentsTypesOptions } from "../../libs/constants";

import Icon from "../../ui/Icon";

function TabDocuments() {

    const { peopleData, updatePeopleData } = useGenealogyStore();

    const hiddenFileInput = useRef(null);

    const handleFileInputClick = () => {
        hiddenFileInput.current.click();
    };

    useEffect(() => {

        console.log("documents", peopleData.documents);

    }, [peopleData.documents]);

    const handleDocumentUpload = (e) => {
        e.preventDefault();
        const now = new Date();
        const documentType = peopleData.documents.filter((document) => document.to_upload).shift().type;
        let fileName = "document_" + documentType + "_" + peopleData.name_last + "_" + peopleData.name_first + "_" + now.toLocaleDateString("en-US").replaceAll("/", "-") + "_" + now.toLocaleTimeString("fr-FR").replaceAll(":", "-");
        fileName = fileName.replaceAll(" ", "-").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const filePath = "genealogy/documents/";
        const fileData = new FormData();
        fileData.append("file", e.target.files[0]);
        fileData.append("file_name", fileName);
        fileData.append("file_path", filePath);
        uploadFile(fileData).then((response) => {
            const fileURL = response.result.url;
            let newDocuments = peopleData.documents;
            let newDocument = newDocuments.pop();
            newDocument.url = fileURL;
            newDocument.to_save = true;
            delete newDocument.to_upload;
            updatePeopleData('documents', [...newDocuments, newDocument]);
        });
    }

    return (<>
        <UIFormFieldset legend="Documents" icon="file-lines">
            <Row>
                <Col>
                    <div className={peopleData.documents.length > 0 ? `mb-4` : ``}>
                        {peopleData.documents.map((document) => {
                            if (document.to_define || document.to_upload) {
                                return (<>
                                    <div className="item-document px-2">
                                        <p className="mb-2">
                                            <Icon name={documentsTypesOptions.filter((option) => option.value === "new").shift().icon} className="me-2" />
                                            {documentsTypesOptions.filter((option) => option.value === "new").shift().label}
                                        </p>
                                        <UISelect
                                            placeholder="Choisissez le type de document"
                                            options={documentsTypesOptions.filter((option) => option.value !== "new")}
                                            noPadding
                                            setValue={(v) => {
                                                let newDocuments = peopleData.documents;
                                                newDocuments.pop();
                                                let newDocument = {
                                                    id: '',
                                                    type: v,
                                                    url: '',
                                                    to_upload: true
                                                }
                                                updatePeopleData('documents', [...newDocuments, newDocument]);
                                            }}
                                        />
                                        <div className="d-grid mt-3">
                                            <Button
                                                variant="primary"
                                                onClick={handleFileInputClick}
                                                disabled={!document.to_upload}
                                            >
                                                <Icon name="file-upload" className="me-2" />
                                                Envoyer un document
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                ref={hiddenFileInput}
                                                style={{ display: "none" }}
                                                onChange={handleDocumentUpload}
                                            />
                                        </div>
                                    </div>
                                </>)
                            }
                            return (<>
                                <div className="item-document px-2">
                                    <p className="mb-2">
                                        <Icon name={documentsTypesOptions.filter((option) => option.value === document.type).shift().icon} className="me-2" />
                                        {documentsTypesOptions.filter((option) => option.value === document.type).shift().label}
                                    </p>
                                    <div className="d-flex">
                                        <a href={`https://${document.url}`} target="_blank" className="d-grid w-50 me-3">
                                            <Button variant="outline-primary">
                                                <Icon name="external-link" className="me-2" />
                                                Voir le document
                                            </Button>
                                        </a>
                                        <div className="d-grid w-50 ms-3">
                                            {!document.to_delete && <Button variant="outline-danger"
                                                onClick={() => {
                                                    let newDocuments = peopleData.documents;
                                                    newDocuments = newDocuments.map((a_document) => {
                                                        if (a_document.id === document.id) {
                                                            // if (a_document.to_save) {
                                                            //     return false;
                                                            // }
                                                            a_document.to_delete = true;
                                                        }
                                                        return a_document;
                                                    });
                                                    updatePeopleData('documents', newDocuments);
                                                }}
                                            >
                                                <Icon name="trash" className="me-2" />
                                                Supprimer le document
                                            </Button>}
                                            {document.to_delete && <>
                                                <span className="text-danger">Fichier à supprimer</span>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                    <div className="d-grid px-2 mt-2">
                        <Button variant="secondary"
                            onClick={() => {
                                let newDocument = {
                                    id: '',
                                    type: 'new',
                                    url: '',
                                    to_define: true
                                }
                                updatePeopleData('documents', [...peopleData.documents, newDocument]);
                            }
                            }
                        >
                            <Icon name="plus" className="me-2" />
                            Ajouter un document
                        </Button>
                    </div>
                </Col>
            </Row>
        </UIFormFieldset>
    </>)
}

export default TabDocuments;