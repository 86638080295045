
import useAppStore from '../../stores/AppStore.jsx';

export default function UIIcon({ name, iconClass, className, fixedWidth }) {

    const { appThemeIconClass } = useAppStore();

    if (iconClass === undefined || !iconClass) {
        iconClass = appThemeIconClass;
    }

    return (
        <i className={`fa-${iconClass} fa-${name}${fixedWidth ? ' fa-fw' : ''}${className ? ` ${className}` : ''}`}></i>
    );

}


