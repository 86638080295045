import React from "react";
import { Form } from "react-bootstrap";
import UIIcon from "../UIIcon";

function UIFormFieldset({ legend, icon, children }) {
    return (<>
        <Form.Group as="fieldset" className='border border-secondary rounded-3 px-3 mb-3'>
            <Form.Label as="legend" className="mb-0">
                {icon && <UIIcon name={icon} className="me-2" />}
                {legend}
            </Form.Label>
            {children}
        </Form.Group>
    </>)
}

export default UIFormFieldset;