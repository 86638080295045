const API_URL = 'api.guru-worlds.com';
const PASSPORT_NAME = 'passport_genealogy';

export const checkApplication = async () => {
  const API_CALL_URL = API_URL + '/authority';
  var formData = new FormData();
  formData.append('action', 'checkApplication');
  const options = {
    method: 'POST',
    body: formData,
    headers: {
      "X-Dev-Origin": "https://genealogie.julien.family",
    },
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  console.info("Application data", data.result);
  return data;
};

export const askAppPassport = async () => {
  if (localStorage.getItem(PASSPORT_NAME)) {
    return localStorage.getItem(PASSPORT_NAME);
  }
  const API_CALL_URL = API_URL + '/authority';
  var formData = new FormData();
  formData.append('action', 'askPassport');
  const options = {
    method: 'POST',
    body: formData,
    headers: {
      "X-Dev-Origin": "https://genealogie.julien.family",
    },
  };
  const data = await fetch('https://' + API_CALL_URL, options);
  const passportData = await data.json();
  let passport = '';
  if (passportData.status === "Accepted") {
    passport = passportData.result;
    localStorage.setItem(PASSPORT_NAME, passport);
  }
  return passportData;
};

export const askUserPassport = async (formData) => {
  const API_CALL_URL = API_URL + '/authority';
  const sha1 = require('js-sha1');
  const sendData = {
    action: 'askPassport',
    login: formData.login,
    key: sha1(formData.password)
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(sendData),
    headers: {
      "X-Dev-Origin": "https://genealogie.julien.family",
    },
  };
  const data = await fetch('https://' + API_CALL_URL, options);
  const passportData = await data.json();
  let passport = '';
  if (passportData.status === "Accepted") {
    passport = passportData.result;
    localStorage.setItem(PASSPORT_NAME, passport);
  }
  return passportData;
};

export const checkPassport = async () => {
  const API_CALL_URL = API_URL + '/authority';
  const passport = localStorage.getItem(PASSPORT_NAME) ? localStorage.getItem(PASSPORT_NAME) : false;
  if (!passport) {
    return false;
  }
  const sendData = {
    action: 'checkPassport',
    passport: passport,
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(sendData),
    headers: {
      "X-Dev-Origin": "https://genealogie.julien.family",
    },
  };
  const data = await fetch('https://' + API_CALL_URL, options);
  const checkData = await data.json();
  // console.log(checkData);
  return checkData;
};

export const updateApplication = async (appData) => {
  const API_CALL_URL = API_URL + '/application';
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + passport);
  headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(appData),
  };
  const response = await fetch('https://' + API_CALL_URL, options);
  const data = await response.json();
  return data;
};

export const fetchGoogleFonts = async () => {
  const API_CALL_URL = API_URL + "/data/googlefonts";
  const passport = localStorage.getItem(PASSPORT_NAME);
  let headers = new Headers();
  headers.append("Authorization", "Bearer " + passport);
  headers.append('X-Dev-Origin', 'https://genealogie.julien.family');
  const options = {
    method: "GET",
    headers: headers,
  };
  const response = await fetch("https://" + API_CALL_URL, options);
  const data = await response.json();
  console.info("Google Fonts data", data.result);
  return data;
};