import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead, AsyncTypeahead, Input } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import { fetchCity } from "../../services/genealogy";

// import './style.scss';

function UIFieldAutocomplete({ label, options, placeholder, value, setValue, onSearch, inactive }) {

    const [selectedOption, setSelectedOptions] = useState([]);
    const [allOptions, setAllOptions] = useState([]);

    useEffect(() => {
        setAllOptions(options);
        setSelectedOptions(options.filter((option) => option.city === value));
    }, [options]);

    useEffect(() => {
        console.log(value);
    }, [value]);
    return (<>
        <Form.Group className="px-2">
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            {inactive && <Form.Control
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => { setValue(e.target.value, {}) }}
            />}
            {!inactive && <AsyncTypeahead
                placeholder={placeholder}
                options={allOptions}
                selected={selectedOption}
                isLoading={false}
                labelKey={option => `${option.city}`}
                onSearch={(q) => { console.log('search'); onSearch(q) }}
                id="birthCity"
                onChange={(e) => { console.log("change"); setSelectedOptions(e); if (e[0]) { console.log("ok"); setValue(e[0].city, e[0]) } }}
                onInputChange={(t) => { console.log("inputChange"); setSelectedOptions([]); onSearch(t); setValue(t, {}) }}
            />}
        </Form.Group>
    </>)
}

export default UIFieldAutocomplete;