import { Col, Row, Stack } from "react-bootstrap";
import useGenealogyStore from "../../stores/GenealogyStore";

import UIData from '../../ui/UIData';

export default function TabIdentity() {

    const { peopleData } = useGenealogyStore();
    return (<>
        <Row>
            <Col className="py-2">
                <UIData value={peopleData} />
            </Col>
        </Row>
    </>)
}