import React from 'react';
import { Row, Col } from 'react-bootstrap';
// Stores
import useGenealogyStore from "../../stores/GenealogyStore";
import UIData from '../../ui/UIData';
import UIFormFieldset from '../../ui/UIFormFieldset';


function TabData({ children }) {

    const { peopleData } = useGenealogyStore();

    return (<>
        <UIFormFieldset legend="Données" icon="brackets-curly">
            <Row>
                <Col>
                    <UIData
                        value={peopleData}
                    />
                </Col>
            </Row>
        </UIFormFieldset>
    </>)

}

export default TabData;