import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en_US: {
    translation: {
      "page_title_peoples_list": "List of persons",
      "page_title_tree_view": "Family view",
      "page_title_people_view": "People view",
      "page_title_gallery_photo": "Gallery photo",
    }
  },
  fr_FR: {
    translation: {
      "page_title_peoples_list": "Répertoire des personnes",
      "page_title_tree_view": "Arbre généalogique",
      "page_title_people_view": "Détail de la personne",
      "page_title_gallery_photo": "Galerie photo",
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr_FR",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;