import Masonry from 'react-masonry-css';

export default function UIMasonry({ cols, children }) {
    return (<>
        <Masonry
            breakpointCols={cols}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"

        >
            {children}
        </Masonry>
    </>)
}