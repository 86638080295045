import { Col, Row } from "react-bootstrap";
import useGenealogyStore from "../../stores/GenealogyStore";

import UIIcon from "../../ui/Icon";

import ItemFamily from "./ItemFamily";

export default function TabFamily() {

    const { peopleData, peoplesData } = useGenealogyStore();

    return (<>
        {/* <Row>
            <Col>
                <h4 className="text-muted text-center"><UIIcon name="up-from-line" className="me-3" />Ascendants</h4>
            </Col>
        </Row> */}
        <Row className="pt-4">
            <Col className="text-center">
                <h5 className="text-muted text-center">Grand-mère maternelle</h5>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === peopleData.mother_mother_id).shift()} />
            </Col>
            <Col className="text-center">
                <h5 className="text-muted text-center">Grand-père maternel</h5>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === peopleData.mother_father_id).shift()} />
            </Col>
            <Col className="text-center">
                <h5 className="text-muted text-center">Grand-mère paternelle</h5>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === peopleData.father_mother_id).shift()} />
            </Col>
            <Col className="text-center">
                <h5 className="text-muted text-center">Grand-père paternel</h5>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === peopleData.father_father_id).shift()} />
            </Col>
        </Row>
        
        <Row>
            <Col className="text-center">
                <h5 className="text-muted text-center">Mère</h5>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === peopleData.mother_id).shift()} />
            </Col>
            <Col className="text-center">
                <h5 className="text-muted text-center">Père</h5>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === peopleData.father_id).shift()} />
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <h5 className="text-muted text-center">Frères & Soeurs</h5>
            </Col>
        </Row>
        <Row>
            <Col className="d-flex justify-content-center">
            {peopleData.siblings_ids.map((siblings_id) => (<div className="text-center px-3" style={{width:220}}>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === siblings_id).shift()} />
            </div>))}
            </Col>
        </Row>
        <Row>
            <Col className="text-center mt-3">
                <h5 className="text-muted text-center">Enfants</h5>
            </Col>
        </Row>
        <Row>
        <Col className="d-flex justify-content-center">
            {peopleData.childrens_ids.map((childrens_id) => (<div className="text-center px-3" style={{width:220}}>
                <ItemFamily peopleData={peoplesData.filter((people) => people.id === childrens_id).shift()} />
            </div>))}
            </Col>
        </Row>
    </>)
}