import { Col, Row, Table, Badge } from "react-bootstrap";
import useGenealogyStore from "../../stores/GenealogyStore";
import moment from "moment";
import 'moment/locale/fr';
import UIIcon from "../../ui/UIIcon";

export default function TabInfos() {

    const { peopleData } = useGenealogyStore();
    return (<>
        <Row>
            <Col>
                <h4 className="text-center mt-2 mb-4"><UIIcon name="pencil" className="me-2" />Modifications<Badge bg="primary">{peopleData.updates.length}</Badge></h4>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr className="text-center">
                            {/* <th>\</th> */}
                            <th>Type</th>
                            <th>Type de donnée</th>
                            <th>Nom de la donnée</th>
                            <th>Ancienne valeur</th>
                            <th>Nouvelle valeur</th>
                            <th>Date</th>
                            <th>Utilisateur</th>
                        </tr>
                    </thead>
                    <tbody>
                        {peopleData.updates.map((update) => (
                            <tr>
                                <td>{update.type}</td>
                                <td>{update.data_type}</td>
                                <td>{update.data}</td>
                                <td>{update.old_value}</td>
                                <td>{update.new_value}</td>
                                <td className="text-end text-muted">{moment(update.date).locale('fr').format("L")} {moment(update.date).locale('fr').format("LT")}</td>
                                <td className="text-end text-muted">{update.updator}</td>
                            </tr>
                        ))}
                    </tbody>
                    </Table>
            </Col>
        </Row>
    </>)
}