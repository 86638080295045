import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Bootstrap
import { Stack, Button, Form, Tabs, Tab } from "react-bootstrap";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";
import useAppStore from "../../stores/AppStore";

// Components
import AppHeader from "../../components/AppHeader";
import AppNav from "../../components/AppNav";

// Tabs
import TabIdentity from "../PageEdit/TabIdentity";
import TabData from "../PageEdit/TabData";
import TabLife from "../PageEdit/TabLife";
import TabFamily from "../PageEdit/TabFamily";
import TabNotes from "../PageEdit/TabNotes";
import TabBiology from "../PageEdit/TabBiology";
import TabEvents from "../PageEdit/TabEvents";
import TabDocuments from "../PageEdit/TabDocuments";

// UI
import UIIcon from "../../ui/UIIcon";
import UIButton from "../../ui/UIButton";

function PageNew() {

    const navigate = useNavigate();
    const params = useParams();
    const {
        initNewPeople,
        peoplesData, peopleIsFetched, peopleAdded,
        peopleIsCreating,
        addPeopleData
    } = useGenealogyStore();

    const {
        setLastAction
    } = useAppStore();

    useEffect(() => {
        initNewPeople();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!peopleIsCreating) {
            addPeopleData().then((result) => {
                if (result) {
                    setLastAction("create_people");
                    navigate('/people/' + result + '/edit')

                }
            });
        }


        return false;

    }

    return (<>
        <div className='App'>
            <AppHeader />
            <AppNav />
            {peopleIsFetched && <section className="App-section">
                <Stack direction="horizontal" className="mb-3 flex-column flex-sm-row App-section-header">
                    <h2><UIIcon name="plus" className="me-3" />Nouvelle personne</h2>
                </Stack>
                <Form onSubmit={handleSubmit}>
                    <Tabs
                        defaultActiveKey="people"
                        className="mb-2"
                        justify
                        variant="pills"
                    >
                        <Tab eventKey="people" title={<><UIIcon name="id-card" className="me-2" />Identité</>}>
                            <TabIdentity />
                        </Tab>
                        <Tab eventKey="biology" title={<><UIIcon name="dna" className="me-2" />Biologie</>}>
                            <TabBiology />
                        </Tab>
                        <Tab eventKey="life" title={<><UIIcon name="wave-pulse" className="me-2" />Vie</>}>
                            <TabLife />
                        </Tab>
                        <Tab eventKey="family" title={<><UIIcon name="family" className="me-2" />Famille</>}>
                            <TabFamily />
                        </Tab>
                        <Tab eventKey="events" title={<><UIIcon name="calendar-star" className="me-2" />Événements</>}>
                            <TabEvents />
                        </Tab>
                        <Tab eventKey="documents" title={<><UIIcon name="file-user" className="me-2" />Documents</>}>
                            <TabDocuments />
                        </Tab>
                        <Tab eventKey="notes" title={<><UIIcon name="text" className="me-2" />Notes</>}>
                            <TabNotes />
                        </Tab>
                        <Tab eventKey="data" title={<><UIIcon name="database" className="me-2" />Données</>}>
                            <TabData />
                        </Tab>
                    </Tabs>
                    <Form.Group className="mt-4 pt-4 border-top d-grid">
                        <UIButton
                            type="submit"
                            size="lg"
                            icon="check"
                            label="Ajouter"
                            isWorking={peopleIsCreating}
                        />
                    </Form.Group>
                </Form>
            </section>}
        </div>
    </>)
}

export default PageNew;