import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

// Stores
import useGenealogyStore from '../stores/GenealogyStore';
import useAppStore from '../stores/AppStore';

// Components
import PeopleRow from './PeopleRow';

function PeoplesTable() {

    const { appTheme } = useAppStore();
    const {
        peoplesData, peoplesListed, setPeoplesListed,
        peoplesListOrderField, setPeoplesListOrder
    } = useGenealogyStore();

    const handleChangeOrder = (event) => {
        const field = event.target.dataset.field;
        const order = event.target.dataset.order ? event.target.dataset.order : "";
        let newOrder = "";
        if (order === "" || order === "DESC") {
            event.target.dataset.order = "ASC";
            newOrder = 'ASC';
        }
        else if (order === "ASC") {
            event.target.dataset.order = "DESC";
            newOrder = "DESC";
        }
        setPeoplesListOrder(field, newOrder);
        const newObjects = [...peoplesData];
        newObjects.sort((a, b) => {
            let nameA = "";
            let nameB = "";
            nameA = a[field].toUpperCase();
            nameB = b[field].toUpperCase();
            if (nameA < nameB) {
                return (newOrder === "ASC") ? -1 : 1;
            }
            if (nameA > nameB) {
                return (newOrder === "ASC") ? 1 : -1;
            }
            return 0;
        });
        setPeoplesListed(newObjects);
    }

    return (
        <Table bordered responsive striped variant={appTheme === "dark" ? 'dark' : ''} className='nowrap'>
            <thead>
                <tr>
                    <th></th>
                    <th onClick={handleChangeOrder} className={peoplesListOrderField === "sex" ? "active text-center" : "text-center"} data-field="sex">Sexe</th>
                    <th onClick={handleChangeOrder} className={peoplesListOrderField === "name_last" ? "active text-center" : "text-center"} data-field="name_last">Nom</th>
                    <th onClick={handleChangeOrder} className={peoplesListOrderField === "name_first" ? "active text-center" : "text-center"} data-field="name_first">Prénom</th>
                    <th onClick={handleChangeOrder} className={peoplesListOrderField === "birth_year" ? "active text-center" : "text-center"} data-field="birth_year">Naissance</th>
                    <th onClick={handleChangeOrder} className={peoplesListOrderField === "death_year" ? "active text-center" : "text-center"} data-field="death_year">Décès</th>
                    <th className={"text-center"}></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {peoplesListed.map((people) => (
                    <PeopleRow key={people.id} people={people} />
                ))}
            </tbody>
        </Table>
    )

}

export default PeoplesTable;