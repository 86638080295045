import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import BasicTooltip from '../ui/Tooltip';
import Icon from '../ui/Icon';

import Color from 'color';

// import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import countries from '../data/countries.json';

export const setColors = (appColor) => {
	if (appColor === undefined) {
		return true;
	}
	const color = Color(appColor);
	const systemColor = color.hex();
	let systemColorRGB = color.rgb();
	systemColorRGB = systemColorRGB.color.join(", ");
	const systemColorDark1 = color.darken(0.15).hex();
	const systemColorDark2 = color.darken(0.3).hex();
	const systemColorDark3 = color.darken(0.45).hex();
	const systemColorDark4 = color.darken(0.6).hex();
	const systemColorLight1 = color.lighten(0.15).hex();
	const systemColorLight2 = color.lighten(0.3).hex();
	const systemColorLight3 = color.lighten(0.45).hex();
	const systemColorLight4 = color.lighten(0.6).hex();
	document.querySelector(':root').style.setProperty('--bs-primary', systemColor);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-dark1', systemColorDark1);
	document.querySelector(':root').style.setProperty('--bs-primary-dark2', systemColorDark2);
	document.querySelector(':root').style.setProperty('--bs-primary-dark3', systemColorDark3);
	document.querySelector(':root').style.setProperty('--bs-primary-dark4', systemColorDark4);
	document.querySelector(':root').style.setProperty('--bs-primary-light1', systemColorLight1);
	document.querySelector(':root').style.setProperty('--bs-primary-light2', systemColorLight2);
	document.querySelector(':root').style.setProperty('--bs-primary-light3', systemColorLight3);
	document.querySelector(':root').style.setProperty('--bs-primary-light4', systemColorLight4);
	const systemColorDarken1 = color.darken(0.15).hex();
	const systemColorDarken2 = color.darken(0.3).hex();
	const systemColorDarken3 = color.darken(0.45).hex();
	const systemColorDarken4 = color.darken(0.6).hex();
	const systemColorLighten1 = color.lighten(0.15).hex();
	const systemColorLighten2 = color.lighten(0.3).hex();
	const systemColorLighten3 = color.lighten(0.45).hex();
	const systemColorLighten4 = color.lighten(0.6).hex();
	document.querySelector(':root').style.setProperty('--theme-color', systemColor);
	document.querySelector(':root').style.setProperty('--theme-color-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--theme-color-darken1', systemColorDarken1);
	document.querySelector(':root').style.setProperty('--theme-color-darken2', systemColorDarken2);
	document.querySelector(':root').style.setProperty('--theme-color-darken3', systemColorDarken3);
	document.querySelector(':root').style.setProperty('--theme-color-darken4', systemColorDarken4);
	document.querySelector(':root').style.setProperty('--theme-color-lighten1', systemColorLighten1);
	document.querySelector(':root').style.setProperty('--theme-color-lighten2', systemColorLighten2);
	document.querySelector(':root').style.setProperty('--theme-color-lighten3', systemColorLighten3);
	document.querySelector(':root').style.setProperty('--theme-color-lighten4', systemColorLighten4);
	if (document.querySelector('.btn-primary')) {
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-primary', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-bg', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-color', 'white'); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-border-color', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-bg', systemColorDarken1); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-border-color', systemColorDarken1); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-bg', systemColorDarken2); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-border-color', systemColorDarken2); })
	}
	if (document.querySelector('.btn-outline-primary')) {
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-primary', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-bg', "transparent"); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-color', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-border-color', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-bg', systemColorDarken1); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-border-color', systemColorDarken1); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-bg', systemColorDarken2); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-border-color', systemColorDarken2); })
	}
	if (document.querySelector('.nav-link')) {
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-link-color', systemColor); })
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-pills-link-active-bg', systemColor); })
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-link-hover-color', systemColorDarken1); })
	}

	return true;
};

export const setTheme = (appTheme) => {
	if (appTheme !== 'dark') {
		return true;
	}
	document.querySelector(':root').style.setProperty('--text-color', 'white');
	document.querySelector(':root').style.setProperty('--text-inverse-color', 'black');

	return true;
};

export const formatLifeDates = (people) => {
	if (people.is_living === '1') {
		return people.birth_year !== "" ? people.birth_year : '?';
	}
	let result = people.birth_year !== "" ? people.birth_year : '?';
	result += people.death_year !== "" ? ' — ' + people.death_year : ' — ?';
	return result;
}

export const formatEventDates = (event) => {
	if (event.is_active === '1') {
		return event.start_year !== "" ? event.start_year : '?';
	}
	let result = event.start_year !== "" ? event.start_year : '?';
	result += event.end_year !== "" ? ' — ' + event.end_year : ' — ?';
	return result;
}

export const formatBirthDate = (people) => {
	if (people.birth_year === "" && people.birth_date === '') {
		return '?';
	}

	if (people.birth_year !== "" && people.birth_date === '') {
		return `${people.birth_year}`;
	}

	if (people.birth_year === "" && people.birth_date !== '') {
		return `${people.birth_date.split("-").reverse().join("/")}`;
	}

	return `${people.birth_date.split("-").reverse().join("/")}/${people.birth_year}`;
}

export const formatBirthDateLong = (people) => {
	if (people.birth_year === "" && people.birth_date === '') {
		return '?';
	}

	if (people.birth_year !== "" && people.birth_date === '') {
		return people.sex === 'female' ? `Née en ${people.birth_year}` : `${people.birth_year}`;
	}

	if (people.birth_year === "" && people.birth_date !== '') {
		return people.sex === 'female' ? `Née le ${people.birth_date.split("-").reverse().join("/")}` : `Né le ${people.birth_date.split("-").reverse().join("/")}`;
	}

	return people.sex === 'female' ? `Née le ${people.birth_date.split("-").reverse().join("/")}/${people.birth_year}` : `Né le ${people.birth_date.split("-").reverse().join("/")}/${people.birth_year}`;
}

export const formatDeathDate = (people) => {
	if (people.death_year === "" && people.death_date === '') {
		return '?';
	}

	if (people.death_year !== "" && people.death_date === '') {
		return people.sex === 'female' ? `Décèdée en ${people.death_year}` : `Décèdé en ${people.death_year}`;
	}

	if (people.death_year === "" && people.death_date !== '') {
		return people.sex === 'female' ? `Décèdée le ${people.death_date.split("-").reverse().join("/")}` : `Décèdé le ${people.death_date.split("-").reverse().join("/")}`;
	}

	return people.sex === 'female' ? `Décèdée le ${people.death_date.split("-").reverse().join("/")}/${people.death_year}` : `Décèdé le ${people.death_date.split("-").reverse().join("/")}/${people.death_year}`;
}

export const formatPlace = (placeData) => {
	let result = '';
	if (placeData === undefined) {
		return result;
	}

	if (placeData.city !== undefined && placeData.city === "" && placeData.country !== undefined && placeData.country === "") {
		return '?';
	}

	if (placeData.city !== undefined && placeData.city !== "") {
		result += placeData.city;
	}

	let country = '';
	if (placeData.country !== undefined && placeData.country !== "") {
		if (placeData.city !== undefined && placeData.city !== "") {
			country += ' ';
		};
		country += getUnicodeFlagIcon(placeData.country.toUpperCase());
	}

	let tooltip_content = '';
	if (placeData.departement !== undefined && placeData.departement.name !== undefined) {
		tooltip_content += placeData.departement.name;
		if (placeData.region !== undefined && placeData.region.name !== undefined) {
			tooltip_content += '<br/>';
			tooltip_content += '<em>' + placeData.region.name + '</em>';
		}
	}

	if (tooltip_content !== "") {
		result = <><BasicTooltip content={tooltip_content} placement="bottom">{result}</BasicTooltip><span>{country}</span></>
	}
	else {
		result = `${result} ${country}`;
	}

	return result;
}

export const formatPlaceLong = (placeData) => {

	return (<>
		{placeData.city}
	</>)
	let result = '';
	if (placeData === undefined) {
		return result;
	}

	if (placeData.city !== undefined && placeData.city === "" && placeData.country !== undefined && placeData.country === "") {
		return '?';
	}

	if (placeData.city !== undefined && placeData.city !== "") {
		result += placeData.city;
	}

	if (placeData.departement !== undefined && placeData.departement.name !== undefined) {
		result += (<br/>);
		result += placeData.departement.name;
		if (placeData.region !== undefined && placeData.region.name !== undefined) {
			// tooltip_content += '<br/>';
			result += <em>{` ${placeData.region.name}`}</em>;
		}
	}

	let country = '';
	if (placeData.country !== undefined && placeData.country !== "") {
		// if (placeData.city !== undefined && placeData.city !== "") {
		// 	country += ' ';
		// };
		result += getUnicodeFlagIcon(placeData.country.toUpperCase());
	}

	// let tooltip_content = '';
	// if (placeData.departement !== undefined && placeData.departement.name !== undefined) {
	// 	tooltip_content += placeData.departement.name;
	// 	if (placeData.region !== undefined && placeData.region.name !== undefined) {
	// 		tooltip_content += '<br/>';
	// 		tooltip_content += '<em>' + placeData.region.name + '</em>';
	// 	}
	// }

	// if (tooltip_content !== "") {
	// 	result = <><BasicTooltip content={tooltip_content} placement="bottom">{result}</BasicTooltip><span>{country}</span></>
	// }
	// else {
	// 	result = `${result} ${country}`;
	// }

	return result;
}

export const getPlaceTooltipContent = (placeData) => {


	let tooltip_content = '';
	if (placeData.departement !== undefined && placeData.departement.name !== undefined) {
		tooltip_content += placeData.departement.name;
		if (placeData.region !== undefined && placeData.region.name !== undefined) {
			tooltip_content += '<br/>';
			tooltip_content += '<em>' + placeData.region.name + '</em>';
		}
	}


	return tooltip_content;
}

export const getCountriesOptions = () => {
	const countriesOptions = [
		{ value: 'fr', label: '🇫🇷 France' }];
	Object.keys(countries).forEach(function (key, index) {
		if (key === "FR") return;
		const country = {
			value: key.toLowerCase(),
			label: getUnicodeFlagIcon(key) + " " + countries[key]
		}
		countriesOptions.push(country);
	});
	return countriesOptions;
}

// export const formatTooltipPlace = (placeData,children) => {
// 	let tooltip_content = '';
// 	if(placeData === undefined) {
// 		return {children};
// 	}

// 	if(placeData.city !== undefined && placeData.city === "" && placeData.country !== undefined && placeData.country === "") {
// 		return {children};
// 	}

// 	if(placeData.city !== undefined && placeData.city !== "") {
// 		tooltip_content += placeData.city;
// 	}

// 	if(placeData.country !== undefined && placeData.country !== "") {
// 		if(placeData.city !== undefined && placeData.city !== "") {
// 			tooltip_content += '<br/>';
// 		};
// 		tooltip_content += getUnicodeFlagIcon(placeData.country.toUpperCase());
// 	}

// 	if(placeData.departement !== undefined && placeData.departement.name !== undefined) {
// 		tooltip_content += '<br/>';
// 		tooltip_content += placeData.departement.name;
// 		if(placeData.region !== undefined && placeData.region.name !== undefined) {
// 			tooltip_content += '<br/>';
// 			tooltip_content += '<em>'+placeData.region.name+'</em>';
// 		}
// 	}

// 	if(tooltip_content !== "") {
// 		result = <><BasicTooltip content={tooltip_content} placement="bottom">{children}</BasicTooltip></>
// 	}

// 	return result;
// }

export const formatGrade = (grade) => {
	let result = '';
	if (grade === undefined || grade === '') {
		return result;
	}

	if (grade === "verified") {
		result = <span className='text-success ms-2'><BasicTooltip content="Vérifié" placement="right"><Icon name="check-double" /></BasicTooltip></span>
	}

	if (grade === "correct") {
		result = <span className='text-theme ms-2'><BasicTooltip content="Connu" placement="right"><Icon name="check" /></BasicTooltip></span>
	}

	if (grade === "uncertain") {
		result = <span className='text-orange ms-2'><BasicTooltip content="Incertain" placement="right"><Icon name="question" /></BasicTooltip></span>
	}

	if (grade === "approx") {
		result = <span className='text-red ms-2'><BasicTooltip content="Approximatif" placement="right"><Icon name="triangle-exclamation" /></BasicTooltip></span>
	}

	return result;
}

export const printUnionType = (unionType) => {
	let result = unionType;
	switch (unionType) {
		case 'wedding':
			result = "Mariage";
			break;
		case 'free':
			result = "Concubinage";
			break;
	}
	return result;
}

export const printEventIcon = (eventData) => {
	let result = "calendar-star";
	switch (eventData.type) {
		case 'school':
			switch (eventData.data2) {
				case 'École maternelle':
					result = "child";
					break;

				case 'Collège':
					result = "backpack";
					break;

				case 'Université':
					result = "graduation-cap";
					break;

				default:
					result = "bus-school";
					break;
			}

			break;
		case 'travel':
			result = "passport";
			break;
		case 'diploms':
			result = "diploma";
			break;
		case 'work':
			result = "briefcase";
			break;
	}
	return result;
}

export const printZodiac = (zodiac) => {
	let result = "";
	switch (zodiac) {
		case 'Aries':
			result = "Bélier";
			break;
		case 'Taurus':
			result = "Taureau";
			break;
		case 'Aquarius':
			result = "Verseau";
			break;
	}
	return result;
}

export const printCountryName = (isoCode) => {
	const getCountryNames = new Intl.DisplayNames(['fr'], { type: 'region' });
	return getCountryNames.of(isoCode.toUpperCase());
}

export const printCountryFlag = (isoCode) => {
	return getUnicodeFlagIcon(isoCode.toUpperCase());
}
