import React from "react";
import ReactJson from 'react-json-view'

import './style.scss'

function UIData({ value }) {
    return (<>
        <ReactJson
            src={value}
            theme="monokai"
            collapsed={1}
            enableClipboard={false}
        />
    </>)
}

export default UIData;