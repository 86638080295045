import { create } from 'zustand';
import useAppStore from './AppStore';
import { readPeoples, fetchAncestors, fetchDescendants, createPeople, updatePeople, fetchCity, readStats, readPictures, updatePicture } from '../services/genealogy';
import { formatLifeDates } from '../libs/utils';

const useGenealogyStore = create((set, get) => ({
    peoplesIsFetched: false,
    peoplesOriginalData: [],
    peoplesData: [],
    peoplesListed: [],
    peoplesOptions: [],
    fetchPeoples: async () => {
        console.info("fetchPeoples");
        const peoplesResponse = await readPeoples();
        const peoplesData = peoplesResponse;
        const peoplesOptions = [];
        peoplesData.forEach((people) => {
            const result = {
                label: people.name_last + " " + people.name_first,
                value: people.id,
                sex: people.sex,
                dates: formatLifeDates(people),
                names: people.name_second + " " + people.name_third,
            }
            peoplesOptions.push(result);
        });
        const peoplesOriginalData = [...peoplesData];
        set(() => ({ peoplesData, peoplesOriginalData, peoplesOptions, peoplesListed: peoplesData, peoplesIsFetched: true }));
    },
    setPeoplesListed: (peoplesListed) => {
        set(() => ({ peoplesListed }));
    },
    peoplesSearch: "",
    setPeoplesSearch: (peoplesSearch) => {
        console.log('peoplesSearch', peoplesSearch);
        // localStorage.setItem('peoplesSearch',peoplesSearch);
        const peoplesListed = get().peoplesData.filter((people) => people.name_last.toLowerCase().includes(peoplesSearch.toLowerCase()) || people.name_first.toLowerCase().includes(peoplesSearch.toLowerCase()));
        set(() => ({ peoplesSearch, peoplesListed }));
    },
    peoplesFilters: [],
    setPeoplesFilters: (peoplesFilters) => {
        console.info("setPeoplesFilter");
        console.log(peoplesFilters);
        let peoplesListed = get().peoplesData;
        peoplesFilters.map((filter) => {
            peoplesListed = peoplesListed.filter((people) => people[filter.field] === filter.value);
        })
        console.log(peoplesListed);
        set(() => ({ peoplesFilters, peoplesListed }));
    },
    peopleViewed: null,
    peopleLastViewed: localStorage.getItem("lastViewed") ? localStorage.getItem("lastViewed") : null,
    setLastViewed: (lastViewed) => {
        localStorage.setItem("lastViewed", lastViewed);
        set(() => ({ lastViewed }));
    },
    peopleAdded: null,
    peopleIsEdited: false,
    peopleIsFetched: false,
    peopleIsCreating: false,
    peopleIsUpdating: false,
    peopleData: {},
    fetchPeople: (peopleViewed) => {
        console.info("fetchPeople");
        const peopleData = get().peoplesData.filter((people) => parseInt(people.id) === parseInt(peopleViewed)).shift();
        get().setLastViewed(peopleViewed);
        set(() => ({ peopleViewed, peopleData, peopleIsFetched: true, peopleIsEdited: false }))
    },
    initNewPeople: () => {
        console.info("initNewPeople");
        const peopleData = {
            name_last: '',
            name_first: '',
            name_second: '',
            name_third: '',
            sex: '',
            birth_date: '',
            birth_year: '',
            birth_date_grade: '',
            birth_time: '',
            birth_time_grade: '',
            birth_country: '',
            birth_city: '',
            birth_zipcode: '',
            birth_place_grade: '',
            is_living: '1',
            death_date: '',
            death_year: '',
            death_date_grade: '',
            death_time: '',
            death_time_grade: '',
            death_country: '',
            death_city: '',
            death_zipcode: '',
            death_place_grade: '',
            funeral_date: '',
            funeral_year: '',
            funeral_date_grade: '',
            funeral_time: '',
            funeral_time_grade: '',
            funeral_country: '',
            funeral_city: '',
            funeral_zipcode: '',
            funeral_place_grade: '',
            mother_id: '',
            father_id: '',
            childrens_ids: [],
            unions: [],
            events: [],
            documents: [],
            pictures: [],
            blood_group: '',
            blood_rhesus: '',
            notes: '',
        }
        set(() => ({ peopleData, peopleIsFetched: true }));
    },
    updatePeopleData: (peopleDataKey, peopleDataValue) => {
        console.info("updatePeopleData");
        const peopleData = get().peopleData;
        if (peopleData[peopleDataKey] !== peopleDataValue) {
            Object.defineProperty(peopleData, peopleDataKey, { value: peopleDataValue });
            console.log(peopleDataKey, peopleDataValue);
            set(() => ({ peopleData, peopleIsEdited: true }));
        }
    },
    addPeopleData: async () => {
        console.info("addPeopleData");
        set(() => ({ peopleIsCreating: true }));
        let peopleData = get().peopleData;
        peopleData = { ...peopleData, create_type: 'add' };
        console.log('peopleData', peopleData);
        const result = await createPeople(peopleData);
        if (result.status === "Created") {
            const peopleAdded = result.data.id;
            set(() => ({ peopleAdded }));
            await get().resetData();
            set(() => ({ peopleIsCreating: false }));
            return result.data.id;
        }
        return false;
    },
    savePeopleData: async () => {
        console.info("savePeopleData");
        set(() => ({ peopleIsUpdating: true }));
        let peopleData = get().peopleData;
        peopleData = { ...peopleData, update_type: 'save' };
        const peopleViewed = get().peopleViewed;
        const result = await updatePeople(peopleViewed, peopleData);
        if (result.status === "Updated") {
            await get().resetData();
            get().fetchPeople(peopleViewed);
            set(() => ({ peopleIsUpdating: false }));
            return true;
        }
        return false;
    },
    resetPeopleData: async () => {
        console.info("resetPeopleData");
        const peoplesResponse = await readPeoples();
        const peoplesData = peoplesResponse;
        const peopleViewed = get().peopleViewed;
        const peopleData = peoplesData.filter((people) => parseInt(people.id) === parseInt(peopleViewed)).shift();
        set(() => ({ peopleData, peopleIsEdited: false }));
    },
    peoplesListOrderField: 'name_last',
    peoplesListOrderDirection: 'ASC',
    setPeoplesListOrder: (peoplesListOrderField, peoplesListOrderDirection) => {
        set(() => ({ peoplesListOrderField, peoplesListOrderDirection }));
    },
    treeIsFetched: false,
    treeData: {},
    treeViewedPeople: localStorage.getItem('treeViewedPeople') ? localStorage.getItem('treeViewedPeople') : '1',
    treeViewedDirection: localStorage.getItem('treeViewedDirection') ? localStorage.getItem('treeViewedDirection') : 'ancestors',
    fetchTree: async (treeViewedPeople, treeViewedDirection) => {
        console.info("fetchTree");
        const treeResponse = treeViewedDirection === "ancestors" ? await fetchAncestors(treeViewedPeople, 3) : await fetchDescendants(treeViewedPeople, 3);
        const treeData = treeResponse;
        localStorage.setItem('treeViewedPeople', treeViewedPeople);
        localStorage.setItem('treeViewedDirection', treeViewedDirection);
        set(() => ({ treeData, treeViewedPeople, treeViewedDirection, treeIsFetched: true }));
    },
    treeSeparationSiblings: localStorage.getItem('treeSeparationSiblings') ? parseFloat(localStorage.getItem('treeSeparationSiblings')) : .7,
    treeSeparationNonSiblings: localStorage.getItem('treeSeparationNonSiblings') ? parseFloat(localStorage.getItem('treeSeparationNonSiblings')) : 1.9,
    setTreeSeparationSiblings: (treeSeparationSiblings) => {
        localStorage.setItem("treeSeparationSiblings", treeSeparationSiblings);
        set(() => ({ treeSeparationSiblings }));
    },
    setTreeSeparationNonSiblings: (treeSeparationNonSiblings) => {
        localStorage.setItem("treeSeparationNonSiblings", treeSeparationNonSiblings);
        set(() => ({ treeSeparationNonSiblings }));
    },
    treeZoom: localStorage.getItem('treeZoom') ? parseFloat(localStorage.getItem('treeZoom')) : .6,
    setTreeZoom: (treeZoom) => {
        localStorage.setItem("treeZoom", treeZoom);
        set(() => ({ treeZoom }));
    },
    treeDepthFactor: localStorage.getItem('treeDepthFactor') ? parseInt(localStorage.getItem('treeDepthFactor')) : 470,
    setTreeDepthFactor: (treeDepthFactor) => {
        localStorage.setItem("treeDepthFactor", treeDepthFactor);
        set(() => ({ treeDepthFactor }));
    },
    initData: () => {
        console.info("initData");
        get().fetchPeoples();
        get().fetchTree(get().treeViewedPeople, get().treeViewedDirection);
        get().fetchPictures();
    },
    resetData: async () => {
        console.info("resetData");
        await get().fetchPeoples();
        await get().fetchTree(get().treeViewedPeople, get().treeViewedDirection);
        await get().fetchPictures();
    },
    fetchCity: async (city) => {
        const result = await fetchCity(city);
        // console.log(result);
        return result;
    },
    statsIsFetched: false,
    statsData: {},
    fetchStats: async () => {
        console.info("fetchStats");
        const statsResponse = await readStats();
        const statsData = statsResponse;
        set(() => ({ statsData, statsIsFetched: true }));
    },
    picturesIsFetched: false,
    picturesData: [],
    fetchPictures: async () => {
        console.info("fetchPictures");
        const picturesResponse = await readPictures();
        const picturesData = picturesResponse;
        set(() => ({ picturesData, picturesIsFetched: true }));
    },
    pictureViewed: null,
    pictureIsFetched: false,
    pictureData: {},
    fetchPicture: (pictureViewed) => {
        console.info("fetchPicture");
        const pictureData = get().picturesData.filter((picture) => parseInt(picture.id) === parseInt(pictureViewed)).shift();
        console.info("pictureData", pictureData);
        set(() => ({ pictureViewed, pictureData, pictureIsFetched: true }))
    },
    updatePictureData: (pictureDataKey, pictureDataValue) => {
        console.info("updatePictureData");
        const pictureData = get().pictureData;
        if (pictureData[pictureDataKey] !== pictureDataValue) {
            Object.defineProperty(pictureData, pictureDataKey, { value: pictureDataValue });
            console.log(pictureDataKey, pictureDataValue);
            console.info("pictureData", pictureData);
            set(() => ({ pictureData }));
        }
    },
    savePictureData: async () => {
        console.info("savePictureData");
        let pictureData = get().pictureData;
        pictureData = { ...pictureData, update_type: 'save' };
        const pictureViewed = get().pictureViewed;
        const result = await updatePicture(pictureViewed, pictureData);
        if (result.status === "Updated") {
            await get().fetchPictures();
            get().fetchPicture(pictureViewed);
            return true
        }
        return false;
    },
}));

export default useGenealogyStore;