import React from "react";
import { Row, Col, Form } from "react-bootstrap";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";
import UIEditor from "../../ui/UIEditor";

// UI
import UIFormFieldset from "../../ui/UIFormFieldset";

function TabNotes() {

    const { peopleData, updatePeopleData } = useGenealogyStore();

    return (<>
        <UIFormFieldset legend="Notes" icon="sticky-note">
            <Row >
                <Col>
                    <UIEditor
                        placeholder='Entrez vos notes sur la personne'
                        value={peopleData.notes}
                        setValue={(v) => updatePeopleData('notes', v)}
                    />
                </Col>
            </Row>
        </UIFormFieldset>
    </>)
}

export default TabNotes;