import React, { useState, useEffect, useContext } from 'react';


import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-bootstrap';

import { readPeoples } from '../services/genealogy';
import { updateApplication } from '../services/application';
import { setColors, setTheme } from '../libs/utils';

import useAppStore from '../stores/AppStore';


import BasicToast from '../ui/Toast';

function AppWrapper({ children }) {

    const { t, i18n } = useTranslation();

    const { appLang, appTheme, lastAction, unsetLastAction } = useAppStore();

    // const lastAction = useAppStore((state) => state.lastAction, sh\allow);

    const [toastAction, setToastAction] = useState([]);

    useEffect(() => {
        i18n.changeLanguage(appLang);
        localStorage.setItem('appLang', appLang);
    }, [appLang]);

    useEffect(() => {
        console.log(lastAction);
        // let action = lastAction.shift();
        // const newAction =
        //     (action === "update_people") ? <BasicToast key={Date.now()} title="Personne modifiée !" message="La personne à bien été modifiée" />
        //         : (action === "create_picture") ? <BasicToast key={Date.now()} title="Photo ajoutée !" message="La photo à bien été ajoutée" />
        //             : (action === "update_picture") ? <BasicToast key={Date.now()} title="Photo modifiéé !" message="La photo à bien été modifiée" />
        //                 : (action === "delete_picture") ? <BasicToast key={Date.now()} title="Photo supprimée !" message="La photo à bien été supprimée" />
        //                     : "";
        // setToastAction([...toastAction, newAction]);
    }, [lastAction]);

    const getToastAction = (action) => {
        switch (action.type) {

            case 'create_people':
                return (
                    <BasicToast key={action.id} title="Personne ajoutée !" message="La personne à bien été ajoutée" onClose={() => unsetLastAction(action.id)} />
                )
                break;

            case 'update_people':
                return (
                    <BasicToast key={action.id} title="Personne modifiée !" message="La personne à bien été modifiée" onClose={() => unsetLastAction(action.id)} />
                )
                break;

            case 'update_picture':
                return (
                    <BasicToast key={action.id} title="Photo modifiéé !" message="La photo à bien été modifiée" onClose={() => unsetLastAction(action.id)} />
                )
                break;

            default:
                break;
        }
    }

    return (
        <div className={`App-wrapper theme-${appTheme}`}>
            {children}
            <ToastContainer position="bottom-end" style={{ paddingBottom: 0, paddingRight: 0 }}>
                {/* {toastAction} */}
                {lastAction.map((action) => {
                    return (<>
                        {getToastAction(action)}
                    </>)
                })}
            </ToastContainer>
        </div>
    );
}

export default AppWrapper;
