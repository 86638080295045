import React from "react";
import { Row, Col, Form } from "react-bootstrap";

// Stores
import useGenealogyStore from "../../stores/GenealogyStore";

// UI
import UIFormFieldset from "../../ui/UIFormFieldset";
import UIFieldText from "../../ui/UIFieldText";
import UISelect from "../../ui/UISelect";

import { sexSelectOptions } from "../../libs/constants";

function TabIdentity({ children }) {

    const { peopleData, updatePeopleData } = useGenealogyStore();

    return (<>
        <UIFormFieldset legend="Noms et prénoms" icon="id-card">
            <Row className="mb-3">
                <Col>
                    <UIFieldText
                        type="text"
                        label="Nom de famille"
                        placeholder="Entrez le nom de famille"
                        value={peopleData.name_last}
                        setValue={(v) => updatePeopleData('name_last', v.toUpperCase())}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='d-flex px-2'>
                        <Form.Label className='text-muted'>Prénoms</Form.Label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <UIFieldText
                        type="text"
                        placeholder="Entrez le prénom"
                        value={peopleData.name_first}
                        setValue={(v) => updatePeopleData('name_first', v)}
                    />
                </Col>
                <Col>
                    <UIFieldText
                        type="text"
                        // label="Prénoms"
                        placeholder="Entrez le deuxième prénom"
                        value={peopleData.name_second}
                        setValue={(v) => updatePeopleData('name_second', v)}
                    />
                </Col>
                <Col>
                    <UIFieldText
                        type="text"
                        // label="Prénoms"
                        placeholder="Entrez le troisième prénom"
                        value={peopleData.name_third}
                        setValue={(v) => updatePeopleData('name_third', v)}
                    />
                </Col>
            </Row>
        </UIFormFieldset>
    </>)
}

export default TabIdentity;