import { create } from 'zustand';
import { useTranslation } from 'react-i18next';

import { askUserPassport, checkPassport, askAppPassport, checkApplication, updateApplication } from '../services/application';

import { setColors } from '../libs/utils';

const useAppStore = create((set, get) => ({
    appIsInit: false,
    appIsAuthenticated: false,
    appIsPrivate: false,
    appIsPasswordAuthenticated: false,
    appPassword: null,
    appPrivateIPs: [],
    appAdminIPs: [],
    appIsLoading: true,
    appIsReady: false,
    stopLoading: () => {
        set(() => ({ appIsLoading: false }));
    },
    appData: {},
    appClientIP: null,
    setAppReady: () => {
        set(() => ({ appReady: true }))
    },
    checkApp: async () => {
        console.info("checkApp");
        const appResponse = await checkApplication();
        const appData = appResponse.result;
        const appClientIP = appResponse.request.ip
        set(() => ({ appData, appClientIP }));
        get().initApp();
    },
    initApp: () => {
        console.info("initApp");
        const appName = get().appData.name;
        const appLang = get().appData.locale;
        const appIsInit = get().appData.is_init === '1' ? true : false;
        const appIsAuthenticated = get().appData.is_authenticated === '1' ? true : false;
        const appIsPrivate = get().appData.is_private === '1' ? true : false;
        const appIsPasswordAuthenticated = get().appData.private_password ? true : false;
        const appPassword = appIsPasswordAuthenticated ? get().appData.private_password : null;
        const appPrivateIPs = get().appData.private_authorized_ips.split(",");
        const appAdminIPs = get().appData.admin_ips.split(",");
        document.title = appName;
        localStorage.setItem('appLang', appLang);
        set(() => ({ appName, appLang, appIsInit, appIsAuthenticated, appIsPrivate, appIsPasswordAuthenticated, appPassword, appPrivateIPs, appAdminIPs }));
        get().setAppTheme();
        if (!appIsAuthenticated) {
            get().checkAuthorization();
            get().appLogin();
        }
        get().checkPassport();
        get().setAppReady();
        // get().stopLoading();
    },
    appName: '',
    updateAppName: async (appName) => {
        const currentAppName = get().appName;
        if (currentAppName !== appName) {
            const appData = {
                application_name: appName
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                set(() => ({ appName }));
            }
        }
    },
    appLang: 'fr_FR',
    updateAppLang: async (appLang) => {
        const currentAppLang = get().appLang;
        if (currentAppLang !== appLang) {
            const appData = {
                application_locale: appLang
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                set(() => ({ appLang }));
            }
        }
    },
    appThemeIsLoaded: false,
    setAppTheme: () => {
        console.info("setAppTheme");
        const appThemeColor = get().appData.theme?.color;
        setColors(appThemeColor);
        localStorage.setItem('appThemeColor', appThemeColor);
        const appTheme = get().appData.theme?.theme;
        const appThemeIconClass = get().appData.theme?.icon_class;
        const appThemeFontTitle = get().appData.theme?.title_google_font,
            appThemeFontPageTitle = get().appData.theme?.page_title_google_font,
            appThemeFontText = get().appData.theme?.text_google_font;
        var WebFont = require('webfontloader');
        WebFont.load({
            google: {
                families: [appThemeFontTitle, appThemeFontPageTitle, appThemeFontText]
            }
        });
        document.querySelector(':root').style.setProperty('--theme-title-font', appThemeFontTitle);
        document.querySelector(':root').style.setProperty('--theme-page-title-font', appThemeFontPageTitle);
        document.querySelector(':root').style.setProperty('--theme-text-font', appThemeFontText);
        set((state) => ({ appTheme, appThemeColor, appThemeIconClass, appThemeFontPageTitle, appThemeFontTitle, appThemeFontText, appThemeIsLoaded: true }));
    },
    appTheme: 'dark',
    updateAppTheme: async (appTheme) => {
        console.log('appTheme', appTheme);
        const currentAppTheme = get().appTheme;
        console.log('currentAppTheme', currentAppTheme);
        if (currentAppTheme !== appTheme) {
            const appData = {
                application_theme: appTheme
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                if (appTheme === "dark") {
                    document.body.classList.remove('theme-light');
                    document.body.classList.add('theme-dark');
                    localStorage.setItem('theme', 'dark');
                }
                else {
                    document.body.classList.remove('theme-dark');
                    document.body.classList.add('theme-light');
                    localStorage.setItem('theme', 'light');
                }
                set(() => ({ appTheme }));
            }
        }
    },
    appThemeColor: localStorage.getItem('appThemeColor') ? localStorage.getItem('appThemeColor') : '#03a9f4',
    updateAppThemeColor: async (appThemeColor) => {
        const currentAppThemeColor = get().appThemeColor;
        if (currentAppThemeColor !== appThemeColor) {
            const appData = {
                application_theme_color: appThemeColor
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                setColors(appThemeColor);
                set(() => ({ appThemeColor }));
            }
        }
    },
    appThemeIconClass: 'solid',
    updateAppThemeIconClass: async (appThemeIconClass) => {
        const currentAppThemeIconClass = get().appThemeIconClass;
        if (currentAppThemeIconClass !== appThemeIconClass) {
            const appData = {
                application_theme_icon_class: appThemeIconClass
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                set(() => ({ appThemeIconClass }));
            }
        }
    },
    appThemeFontPageTitle: 'Poppins',
    updateAppThemeFontPageTitle: async (appThemeFontPageTitle) => {
        const currentAppThemeFontPageTitle = get().appThemeFontPageTitle;
        if (currentAppThemeFontPageTitle !== appThemeFontPageTitle) {
            const appData = {
                application_theme_page_title_google_font: appThemeFontPageTitle
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                var WebFont = require('webfontloader');
                WebFont.load({
                    google: {
                        families: [appThemeFontPageTitle]
                    }
                });
                document.querySelector(':root').style.setProperty('--theme-page-title-font', appThemeFontPageTitle);
                set(() => ({ appThemeFontPageTitle }));
            }
        }
    },
    appThemeFontTitle: 'Poppins',
    updateAppThemeFontTitle: async (appThemeFontTitle) => {
        const currentAppThemeFontTitle = get().appThemeFontTitle;
        if (currentAppThemeFontTitle !== appThemeFontTitle) {
            const appData = {
                application_theme_title_google_font: appThemeFontTitle
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                var WebFont = require('webfontloader');
                WebFont.load({
                    google: {
                        families: [appThemeFontTitle]
                    }
                });
                document.querySelector(':root').style.setProperty('--theme-title-font', appThemeFontTitle);
                set(() => ({ appThemeFontTitle }));
            }
        }
    },
    appThemeFontText: 'Poppins',
    updateAppThemeFontText: async (appThemeFontText) => {
        const currentAppThemeFontText = get().appThemeFontText;
        if (currentAppThemeFontText !== appThemeFontText) {
            const appData = {
                application_theme_text_google_font: appThemeFontText
            }
            const updateResult = await updateApplication(appData);
            if (updateResult.status === "Updated") {
                var WebFont = require('webfontloader');
                WebFont.load({
                    google: {
                        families: [appThemeFontText]
                    }
                });
                document.querySelector(':root').style.setProperty('--theme-text-font', appThemeFontText);
                set(() => ({ appThemeFontText }));
            }
        }
    },
    userLogin: async (loginData) => {
        const loginResult = await askUserPassport(loginData);
        console.log('userLogin', loginResult);
        if (loginResult.status === "Error") {
            set((state) => ({ userLoginError: loginResult.error.API }));
        }
        if (loginResult.status === "Accepted") {
            set((state) => ({ userIsLogged: true, appIsReady: true, }));
        }
    },
    appIsLogged: false,
    appLogin: async () => {
        console.info('appLogin');
        const loginResult = await askAppPassport();
        if (loginResult.status === "Accepted") {
            set(() => ({ appIsLogged: true }));
        }
    },
    userIsAdmin: false,
    userLoginError: null,
    userIsLogged: false,
    userIsAuthorized: false,
    checkAuthorization: () => {
        let userIsAuthorized = false;
        if (get().appPrivateIPs.includes(get().appClientIP)) {
            userIsAuthorized = true;
        }
        if (localStorage.getItem("appPassword")) {
            if (localStorage.getItem("appPassword") === get().appPassword) {
                userIsAuthorized = true;
            }
        }
        set(() => ({ userIsAuthorized }));
    },
    userAuthorizeError: null,
    checkPassword: (enteredPassword) => {
        const sha1 = require('js-sha1');
        const userIsAuthorized = sha1(enteredPassword) === get().appPassword;
        const userAuthorizeError = !userIsAuthorized ? 'bad_password' : '';
        if (userIsAuthorized) {
            localStorage.setItem("appPassword", sha1(enteredPassword));
        }
        set(() => ({ userAuthorizeError, userIsAuthorized }));
    },
    checkPassport: async () => {
        console.info("checkPassport");
        const checkResult = await checkPassport();
        if (checkResult.status === "Accepted") {
            const userIsAdmin = get().appAdminIPs.includes(get().appClientIP) ? true : false;
            set(() => ({ userIsLogged: true, appIsReady: true, appIsLoading: false, userIsAdmin }));
        }
        else {
            set(() => ({ appIsLoading: false }));
        }
    },
    showedModal: null,
    showedModalMode: null,
    showModal: (modal, mode) => {
        set((state) => ({ showedModal: modal, showedModalMode: mode }))
    },
    closeModal: () => {
        set((state) => ({ showedModal: null, showedModalMode: null }))
    },
    showedMenu: null,
    showMenu: (menu) => {
        set((state) => ({ showedMenu: menu }))
    },
    closeMenu: () => {
        set(() => ({ showedMenu: null }))
    },
    lastAction: [],
    setLastAction: (action) => {
        console.info("setLastAction");
        const lastAction = get().lastAction;
        const newAction = {
            type: action,
            id: Date.now()
        }
        lastAction.push(newAction);
        set(() => ({ lastAction }))
    },
    unsetLastAction: (id) => {
        console.info("unsetLastAction");
        let lastAction = get().lastAction.filter((action) => action.id !== id);
        set(() => ({ lastAction }));
    },
    lastPage: localStorage.getItem("lastPage") ? localStorage.getItem("lastPage") : null,
    setLastPage: (lastPage) => {
        localStorage.setItem("lastPage", lastPage);
        set(() => ({ lastPage }));
    },
    lastTab: localStorage.getItem("lastTab") ? localStorage.getItem("lastTab") : null,
    setLastTab: (lastTab) => {
        localStorage.setItem("lastTab", lastTab);
        set(() => ({ lastTab }));
    }
}));

export default useAppStore;