import { Button, Spinner } from "react-bootstrap";
import UIIcon from "../UIIcon";

export default function UIButton({ variant, type, size, onClick, className, icon, label, disabled, isWorking }) {

    if (type === undefined) {
        type = "button";
    }

    return (<>
        <Button
            type={type}
            variant={variant}
            size={size}
            className={className}
            onClick={onClick}
            disabled={disabled}
        >
            {!isWorking && <>
                {icon && <UIIcon name={icon} className="me-2" />}
                {label}
            </>}
            {isWorking && <>

                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                />
                Chargement...
            </>}

        </Button>
    </>)
}