export const monthsSelectOptions = [
    { value: '01', label: 'Janvier' },
    { value: '02', label: 'Février' },
    { value: '03', label: 'Mars' },
    { value: '04', label: 'Avril' },
    { value: '05', label: 'Mai' },
    { value: '06', label: 'Juin' },
    { value: '07', label: 'Juillet' },
    { value: '08', label: 'Août' },
    { value: '09', label: 'Septembre' },
    { value: '10', label: 'Octobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Décembre' },
];

export const sexSelectOptions = [
    { value: 'female', label: 'Féminin', icon: 'venus', text_color: 'pink' },
    { value: 'male', label: 'Masculin', icon: 'mars', text_color: 'blue' },
    { value: 'neutral', label: 'Neutre', icon: 'venus-mars', text_color: 'purple' },
];

export const gradeSelectOptions = [
    { value: 'verified', label: 'Vérifiée', icon: 'check-double', text_color: 'success' },
    { value: 'correct', label: 'Connue', icon: 'check', text_color: 'theme' },
    { value: 'uncertain', label: 'Incertaine', icon: 'question', text_color: 'orange' },
    { value: 'approx', label: 'Approximative', icon: 'triangle-exclamation', text_color: 'red' },
];

export const isLivingSelectOptions = [
    { value: '1', label: 'Personne vivante', icon: 'heartbeat' },
    { value: '0', label: 'Personne décèdée', icon: 'angel' },
];

export const bloodGroupOptions = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'AB', label: 'AB' },
    { value: 'O', label: 'O' },
];

export const bloodRhesusOptions = [
    { value: '+', label: 'Positif' },
    { value: '-', label: 'Négatif' },
];

export const documentsTypesOptions = [
    { value: 'new', label: 'Nouveau fichier', icon: 'file-plus' },
    { value: 'birth', label: 'Acte de naissance', icon: 'baby' },
    { value: 'wedding', label: 'Acte de mariage', icon: 'rings-wedding' },
    { value: 'death', label: 'Acte de décès', icon: 'coffin' },
    { value: 'passport', label: 'Passeport', icon: 'passport' },
    { value: 'identity', label: "Carte d'identité", icon: 'id-card' },
    { value: 'military_registry', label: 'Registre militaire', icon: 'wreath' },
    { value: 'file', label: 'Autre fichier', icon: 'file' },
];

export const funeralTypesOptions = [
    { value: 'burial', label: 'Inhumation', icon: 'coffin' },
    { value: 'cremation', label: 'Crémation', icon: 'fire-smoke' },
];

export const unionTypesOptions = [
    { value: 'wedding', label: 'Mariage', icon: 'rings-wedding' },
    { value: 'civil_partnership', label: 'PACS', icon: 'hands-helping' },
    { value: 'cohabitation', label: 'Concubinage', icon: 'home-heart' },
    { value: 'free', label: 'Partenaire', icon: 'heart' },
];

export const eventTypesOptions = [
    { value: 'school', label: 'École', icon: 'bus-school' },
    { value: 'diploms', label: 'Diplôme', icon: 'graduation-cap' },
    { value: 'military_service', label: 'Service militaire', icon: 'medal' },
    { value: 'travel', label: 'Voyage', icon: 'passport' },
    { value: 'relocation', label: 'Déménagement', icon: 'truck-couch' },
    { value: 'work', label: 'Travail', icon: 'briefcase' },
    { value: 'retreat', label: 'Retraite', icon: 'couch' },
];

export const schoolTypesOptions = [
    { value: 'École maternelle', label: 'École maternelle', icon: 'child' },
    { value: 'École primaire', label: 'École primaire', icon: 'bus-school' },
    { value: 'Collège', label: 'Collège', icon: 'backpack' },
    { value: 'Lycée', label: 'Lycée', icon: 'bell-school' },
    { value: 'Université', label: 'Université', icon: 'graduation-cap' },
    { value: 'Études supérieures', label: 'Études supérieures', icon: 'institution' },
];