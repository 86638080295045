import { Col, Row, Stack } from "react-bootstrap";
import useGenealogyStore from "../../stores/GenealogyStore";

import UIData from '../../ui/UIData';
import ItemEvent from "./ItemEvent";

export default function TabTimeline() {

    const { peopleData } = useGenealogyStore();
    return (<>
        <Row>
            <Col>
                <div class="timeline-event">
                    {peopleData.events.map((eventData) => (
                        <ItemEvent eventData={eventData} />
                    ))}
                </div>
            </Col>
        </Row>
    </>)
}