import React from 'react';

import { Link } from "react-router-dom";

import { formatLifeDates } from '../../libs/utils';
import useGenealogyStore from '../../stores/GenealogyStore';
import useAppStore from '../../stores/AppStore';

function ItemFamily({ peopleData }) {

    const { fetchPeople } = useGenealogyStore();


    const { setLastTab } = useAppStore();

    if(!peopleData) {
        return (<div className='family-item'>
            <p className='mb-0'>Inconnu</p>
        </div>)
    }

    return (
        <div className='family-item'>
            {peopleData.id && <p className='mb-0 font-name text-white' style={{ fontWeight: '400' }}><Link to={'/people/' + peopleData.id + '/view'} className="text-white">{peopleData.name_last} {peopleData.name_first}</Link></p>}{!peopleData.id && <p style={{ fontWeight: '600' }}>?</p>}
            {peopleData.id && <p className='mb-2 font-date fw-300'>{formatLifeDates(peopleData)}</p>}
        </div>
    )
}

export default ItemFamily;