import React from "react";
import { Form } from "react-bootstrap";

import './style.scss';

function UIFieldText({ label, as, rows, type, placeholder, value, setValue, className, noPadding, noGroup }) {

    if (as === "textarea") {
        if (noGroup) {
            return (<>
                <Form.Control
                    className={className}
                    as={as}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => { setValue(e.target.value) }}
                />
            </>)
        }
        return (<>
            <Form.Group className={noPadding ? className : `${className} px-2`}>
                {label && <Form.Label className='text-muted'>{label}</Form.Label>}
                <Form.Control
                    rows={rows}
                    as={as}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => { setValue(e.target.value) }}
                />
            </Form.Group>
        </>)
    }

    if (noGroup) {
        return (<>
            <Form.Control
                className={className}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
            />
        </>)
    }
    return (<>
        <Form.Group className={noPadding ? className : `${className} px-2`}>
            {label && <Form.Label className='text-muted'>{label}</Form.Label>}
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
            />
        </Form.Group>
    </>)
}

export default UIFieldText;