import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Row, Col, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import selectStyles from '../ui/selectStyles';
import { CustomOptionPeopleMultisex, CustomSingleValue } from '../ui/selectComponents';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import countries from '../data/countries.json';

import moment from 'moment';

import { createPicture, updatePicture, deletePicture, readPictures } from '../services/genealogy';
import { uploadFile, deleteFile, readFileExifData } from '../services/cloud';
import { formatLifeDates } from '../libs/utils';

// Contexts
import useAppStore from '../stores/AppStore';


import { getCountriesOptions } from '../libs/utils';
// UI
import Icon from '../ui/Icon';

import useGenealogyStore from '../stores/GenealogyStore';
import UISelect from '../ui/UISelect';
import UIFieldText from '../ui/UIFieldText';


function ModalPicture({ show, handleClose, editedPicture, pictures, setPictures }) {

    const { t } = useTranslation();
    const { showedModal, showedModalMode, closeModal, lastAction, setLastAction } = useAppStore();
    const { peoplesData, peoplesOptions, pictureData, picturesData, updatePictureData, savePictureData } = useGenealogyStore();

    const countriesOptions = getCountriesOptions();

    const [picturePeoples, setPicturePeoples] = useState("");
    const [pictureURL, setPictureURL] = useState("");
    const [pictureLegend, setPictureLegend] = useState("");
    const [pictureDate, setPictureDate] = useState("");
    const [pictureCity, setPictureCity] = useState("");
    const [pictureCountry, setPictureCountry] = useState("");

    const [mode, setMode] = useState("add");

    const [selectedPeoples, setSelectedPeoples] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);


    useEffect(() => {
        setMode(editedPicture ? "edit" : "add");
        if (editedPicture) {
            const picture = picturesData.filter((a_picture) => a_picture.id === editedPicture).shift();
            console.info("Picture data", picture);
            setPictureURL(picture.url);
            setPictureLegend(picture.legend);
            setPicturePeoples(picture.peoples);
            setPictureDate(picture.date);
            setPictureCity(picture.city);
            setPictureCountry(picture.country);
            const peoplesArray = picture.peoples.split(",");
            const results = peoplesOptions.filter((option) => peoplesArray.includes(option.value));
            setSelectedPeoples(results);
            setSelectedCountry(countriesOptions.filter((option) => option.value === picture.country).shift());
        }
        else {
            setPictureURL("");
            setPictureLegend("");
            setPictureDate("");
            setPictureCity("");
            setPictureCountry("");
            setPicturePeoples("");
            setSelectedPeoples([]);
            setSelectedCountry([]);
        }
    }, [show]);

    const handleSubmit = (event) => {
        event.preventDefault();



        if (showedModalMode === "add") {
            const pictureData = {
                picture_peoples: picturePeoples,
                picture_url: pictureURL,
                picture_legend: pictureLegend,
            }
            createPicture(pictureData).then((response) => {
                if (response.status === "Created") {
                    console.log("✅ Picture created");
                    readPictures()
                        .then((response) => {
                            setPictures(response);
                            setLastAction([...lastAction, "create_picture"]);
                            handleClose();
                        });

                }
            });
        }
        else {
            savePictureData().then((result) => {
                console.log(result);
                if (result) {
                    setLastAction("update_picture");
                    closeModal();
                }
            });

        }
    }

    const handlePictureUpload = (event) => {
        event.preventDefault();
        const now = new Date();
        let fileName = "picture_" + now.toLocaleDateString("en-US").replaceAll("/", "-") + "_" + now.toLocaleTimeString("fr-FR").replaceAll(":", "-");
        fileName = fileName.replaceAll(" ", "-").toLowerCase();
        const filePath = "genealogy/pictures/";
        const fileData = new FormData();
        fileData.append("file", event.target.files[0]);
        fileData.append("file_name", fileName);
        fileData.append("file_path", filePath);
        uploadFile(fileData).then((response) => {
            const fileURL = response.result.url;
            console.log(fileURL);
            setPictureURL(fileURL);
        });
    }

    const handlePictureDelete = (fileURL) => {
        deleteFile(fileURL)
            .then(() => {
                deletePicture(editedPicture).then((response) => {
                    if (response.status === "Deleted") {
                        console.log("✅ Picture deleted");
                        readPictures()
                            .then((response) => {
                                setPictures(response);
                                // setLastAction([...lastAction, "delete_picture"]);
                                handleClose();
                            });
                    }
                });
            })
    }

    const handleGetInfoExif = (fileURL) => {
        readFileExifData(fileURL)
            .then((response) => {
                console.clear();
                console.log(response);
                if (response.IFD0 !== undefined) {
                    if (response.IFD0.DateTime !== undefined) {

                        console.info(response.IFD0.DateTime);
                        const exifDateTime = response.IFD0.DateTime.replace(":", "/").replace(":", "/");

                        console.info(exifDateTime);
                        var now = new Date(exifDateTime);
                        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
                        var output = now.toISOString().slice(0, 16);
                        console.log(output);
                        updatePictureData('date', output);
                    }
                }
                if (response.location !== undefined) {
                    if (response.location.city !== undefined && response.location.city !== "") {
                        updatePictureData('city', response.location.city);
                    }
                    if (response.location.country !== undefined && response.location.country !== "") {
                        updatePictureData('country', response.location.country);
                        // setSelectedCountry(countriesOptions.filter((option) => option.value === response.location.country).shift());
                    }
                }
            })
    }

    const handlePictureReadLocationEXIF = (fileURL) => {
        readFileExifData(fileURL)
            .then((response) => {
                console.clear();
                console.log(response);

            })
    }

    const selectPeoples = (values) => {
        console.log(values);
        setSelectedPeoples(values);
        const results = [];
        values.forEach((value) => {
            results.push(value.value);
        });
        setPicturePeoples(results.join(","));
    };

    return (
        <Modal show={showedModal === "picture"} onHide={() => closeModal()} centered size={showedModalMode === "edit" ? "xl" : 'lg'}>

            <Modal.Header closeButton>
                <Modal.Title >{mode === "add" ? "Nouvelle photo" : "Modifier la photo"}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Row>
                        {pictureData.url && <Col sm={4}>
                            {pictureData.url && <div className='p-2'>
                                <img src={`https://${pictureData.url}`} style={{ width: '100%', maxWidth: '100%' }} />
                                {showedModalMode === "edit" && <div className='d-grid mt-4'>
                                    <Button type="button" variant="outline-danger" size="sm" onClick={() => handlePictureDelete(pictureData.url)}><Icon name="trash" className="me-2" />Supprimer la photo</Button>
                                </div>}
                                {showedModalMode === "edit" && <div className='d-grid mt-2'>
                                    <Button type="button" variant="outline-secondary" size="sm" onClick={() => handleGetInfoExif(pictureData.url)}><Icon name="search" className="me-2" />Lire les infos EXIF</Button>
                                </div>}
                            </div>}

                        </Col>}
                        <Col sm={pictureData.url ? 8 : 12}>
                            {!pictureData.url && <Form.Group className="mb-3">
                                <Form.Label>Fichier</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handlePictureUpload}
                                />
                            </Form.Group>}
                            <Row className='mb-3'>
                                <Col>
                                    <UISelect
                                        label="Personnes"
                                        placeholder="Sélectionnez les personnes sur la photo"
                                        type="peoples_multisex"
                                        options={peoplesOptions}
                                        value={peoplesOptions.filter((people) => pictureData.peoples?.includes(people.value))}
                                        setValue={(v) => updatePictureData('peoples', v)}
                                        isMulti={true}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <UIFieldText
                                        label="Légende"
                                        placeholder="Entrez une légende de la photo"
                                        as="textarea"
                                        rows={2}
                                        value={pictureData.legend}
                                        setValue={(v) => updatePictureData('legend', v)}
                                    />
                                </Col>
                            </Row>
                            {showedModalMode === "edit" && <Row className='mb-3'>
                                <Col>
                                    <UIFieldText
                                        label="Date"
                                        placeholder="Entrez la date de la photo"
                                        type="datetime-local"
                                        value={pictureData.date}
                                        setValue={(v) => {
                                            const newDate = moment(v).format('YYYY-MM-DD HH:mm:ss');
                                            console.log(newDate);
                                            updatePictureData('date', newDate)
                                        }}
                                    />
                                </Col>
                            </Row>}
                            {showedModalMode === "edit" && <Row className='mb-2'>
                                <Col>
                                    <UIFieldText
                                        label="Lieu"
                                        placeholder="Entrez la ville de la photo"
                                        type="text"
                                        value={pictureData.city}
                                        setValue={(v) => updatePictureData('city', v)}
                                    />
                                </Col>
                            </Row>}
                            {showedModalMode === "edit" && <Row className='mb-3'>
                                <Col>
                                    <UISelect
                                        placeholder="Choisissez le pays de la photo"
                                        options={countriesOptions}
                                        value={countriesOptions.filter((country) => country.value === pictureData.country)}
                                        setValue={(v) => updatePictureData('country', v)}
                                    />
                                </Col>
                            </Row>}
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <div className="d-grid w-100">
                        <Button type="submit" variant="primary" size="lg">
                            <Icon name="check" className="me-2" />{showedModalMode === "add" ? "Ajouter" : "Enregistrer"}
                        </Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ModalPicture;